@import '../../../styles/variables.scss';

.container {
  @include page-container;
  display: flex;
  flex-direction: column;
  header {
    padding: 0px 20px;
    @media only screen and (min-width: $md-screen) {
      display: flex;
      justify-content: space-between;
      padding: 20px 40px;
    }
    .title {
      h2 {
        @include title-2;
        margin-bottom: 10px;
        text-transform: uppercase;
      }
      .link {
        @include back-link;
      }
    }
    .btn-container {
      width: 100%;
      @media only screen and (min-width: $md-screen) {
        width: auto;
        display: flex;
        justify-content: flex-end;
      }
      button {
        width: 100%;
        @media only screen and (min-width: $md-screen) {
          width: 220px;
          min-height: 57px;
        }
      }
      a {
        &:-webkit-any-link {
          text-decoration: none;
        }
      }
      .edit {
        @include edit-btn;
      }
    }
  }
  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-top: 10px;
    width: 100%;
    height: calc(100vh - 120px);
    overflow-y: auto;
    @include scrollbar;
    z-index: 3;
    @media only screen and (min-width: $md-screen) {
      padding: 10px 40px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }
    label {
      @include label;
      color: rgba($dark, 0.7);
    }
    aside {
      display: flex;
      flex-direction: column;
      width: 100%;
      @media only screen and (min-width: $md-screen) {
        width: calc(35% - 20px);
      }
      .filters {
        border-radius: $radius;
        background-color: $primary-color;
        box-shadow: 0px 4px 20px rgba($dark, 0.25);
        padding: 15px;
        display: none;
        @media only screen and (min-width: $md-screen) {
          display: block;
        }
        .tags {
          margin-bottom: 20px;
        }
        label {
          color: $white;
        }
      }
      .counter {
        @include box-static;
        padding: 15px;
        margin-top: 20px;
        margin-bottom: 0px;
        p {
          @include title-2;
          font-size: 48px;
          color: $primary-color;
          margin: 15px 0px 10px 0px;
        }
      }
    }
    .company {
      @include box-static;
      width: 100%;
      padding: 20px;
      @media only screen and (min-width: $md-screen) {
        width: 65%;
      }
      h1 {
        margin: 0px;
      }
      .border {
        @include border;
      }
      .infos {
        display: flex;
        .col {
          width: 100%;
          p {
            margin: 0px;
          }
        }
      }
      .toggle {
        @include toggle;
      }
      .list {
        flex-wrap: wrap;
        display: flex;
        margin: 10px 0px;
        .item {
          @include transition;
          @include bold;
          width: auto;
          font-size: 14px;
          background-color: rgba($primary-color , 0.16);
          padding: 5px 12px ;
          border-radius: 5px;
          text-decoration: none;
          margin: 0px  10px 10px 0px;
          &:hover {
            background-color: rgba($primary-color , 0.3);
          }
        }
        .link {
          @include transition;
          @include bold;
          width: auto;
          display: flex;
          align-items: center;
          font-size: 14px;
          padding: 3px 0px ;
          border-radius: 5px;
          text-decoration: underline;
          color: $primary-color;
          margin: 0px  10px 10px 0px;
          svg {
            margin-top: 2px;
            margin-left: 3px;
          }
        }
        &.links {
          flex-wrap: nowrap;
          flex-direction: column;
          background-color: transparent;
        }
      }
      .team-container {
        .team-name {
          display: flex;
          align-items: center;
          @include bold;
          p {
            margin: 5px 0px;
            &:last-child {
              margin-left: 10px;
              background-color: rgba($primary-color , 0.16);
              padding: 5px 8px;
              border-radius: 5px;
            }
          }
        }
        .members {
          margin-bottom: 15px;
          display: flex;
          flex-direction: column;
          p {
            margin: 1px;
            color: rgba($dark, 0.7);
          }
        }
      }
    }
  }
}