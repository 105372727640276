@mixin title-big-1 {
	font-family: "Montserrat-Bold";
  font-weight: normal;
  font-size: 100px;
  line-height: 1.3em;
}

@mixin title-1 {
  font-family: "Anybody-Bold";;
  font-weight: normal;
  font-size: 32px;
}

@mixin title-2 {
  font-family: "Anybody-Bold";;
  font-weight: normal;
  font-size: 20px;
  line-height: 20px;
}

@mixin title-3 {
  font-family: "Ubuntu-Regular";
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}

@mixin title-4 {
	font-family: "Montserrat-SemiBold";
  font-weight: normal;
  font-size: 20px;
  line-height: 22px;
}

@mixin label {
	font-family: "Ubuntu-Regular";
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
}

@mixin header-item {
	font-family: "Montserrat-Regular";
  font-weight: normal;
  font-size: 15px;
  color: $white;
  transition: color .3s ease-in-out;
  &:hover{
      color : $primary-color;
  }
}

// NUNITO

@mixin text {
	font-family: "Ubuntu-Regular";
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color : $black;
}

@mixin bold {
	@include text;
  font-weight: 600;
}
@mixin title-section {
  @include title-3;
  text-transform: uppercase;
  margin: 0px;
  color: $primary-color;
}

@mixin text-small {
	font-family: "Montserrat-Regular";
  font-weight: normal;
  font-size: 13px;
  color : $text-color-light;
}

@mixin text-button {
	font-family: "Anybody-SemiBold";
  font-weight: normal;
  font-size: 14px;
}
