@import '../../../styles/variables.scss';

.container {
  @include page-container;
  display: flex;
  flex-direction: column;
  header {
    padding: 0px 20px;
    @media only screen and (min-width: $md-screen) {
      display: flex;
      justify-content: space-between;
      padding: 20px 40px;
    }
    .title {
      h2 {
        @include title-2;
        margin-bottom: 10px;
        text-transform: uppercase;
        display: none;
        @media only screen and (min-width: $md-screen) {
          display: block;
        }
      }
      .link {
        @include back-link;
      }
    }
    .btn-container {
      width: 100%;
      @media only screen and (min-width: $md-screen) {
        width: auto;
        display: flex;
        justify-content: flex-end;
      }
      button {
        width: 100%;
        @media only screen and (min-width: $md-screen) {
          width: 220px;
          min-height: 57px;
        }
      }
      a {
        text-decoration: none;
      }
      .edit {
        @include edit-btn;
      }
    }
  }
  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    height: calc(100vh - 75px);
    padding-top: 20px;
    overflow: auto;
    @include scrollbar;
    padding: 20px;
    @media only screen and (min-width: $md-screen) {
      flex-direction: row;
      height: calc(100vh - 107px);
      padding: 0px 40px;
    }
    label {
      @include label;
      color: rgba($dark, 0.7);
    }
    .col-center {
      width: 100%;
      @media only screen and (min-width: $md-screen) {
        width: 60%;
      }
      .center {
        display: flex;
        flex-direction: column;
        width: 100%;
        @media only screen and (min-width: $md-screen) {
          flex-direction: row;
          justify-content: space-between;
        }
        h2 {
          @include title-section;
        }
        .infos {
          width: 100%;
          padding: 20px;
          @include box-static;
          h1 {
            margin: 0px;
          }
          .gers {
            margin-top: 10px;
            .label {
              display: flex;
              justify-content: space-between;
              margin: 0px 0px 6px 0px;
              padding: 4px 12px;
              color: rgba($dark, 0.7);
              .col {
                width: 25%;
                &:not(:first-child) {
                  text-align: right;
                }
              }
            }
            .item {
              display: flex;
              justify-content: space-between;
              width: 100%;
              margin: 0px 0px 6px 0px;
              padding: 6px 12px;
              background-color: rgba($primary-color, 0.16);
              border-radius: 8px;
              @include bold;
              .col {
                width: 25%;
                &:not(:first-child) {
                  text-align: right;
                }
              }
            }
          }
          .border {
            @include border;
            .row {
              display: flex;
              flex-direction: column;
              align-items: center;
              @media only screen and (min-width: $xs-screen) {
                flex-direction: row;
                align-items: center;
              }
              .col {
                width: 100%;
                margin-bottom: 10px;
                @media only screen and (min-width: $xs-screen) {
                  width: 33.33%;
                  margin-bottom: 0px;
                }
                p {
                  margin: 0px;
                }
                &.address {
                  width: 100%;
                  display: flex;
                  align-items: flex-start;
                  @media only screen and (min-width: $xs-screen) {
                    width: 66.66%;
                    align-items: center;
                  }
                  svg {
                    color: $primary-color;
                    margin-right: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
    aside {
      width: 100%;
      @media only screen and (min-width: $md-screen) {
        width: calc(40% - 20px);
      }
      .box {
        @include box-static;
        width: 100%;
        padding: 20px;
        h2 {
          @include title-section;
          margin: 0px;
        }
        .list {
          margin: 10px 0px 0px 0px;
          p , a {
            @include transition;
            text-decoration: none;;
            display: block;
            width: 100%;
            margin: 0px 0px 6px 0px;
            padding: 6px 12px;
            background-color: rgba($primary-color, 0.16);
            border-radius: 8px;
            @include bold;
          }
          a {
            cursor: pointer;
            color: $primary-color;
            &:hover {
              background-color: rgba($primary-color, 0.3);
            }
          }
        }
      }
    }
  }
}