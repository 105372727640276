@import '../../../../../styles/variables.scss';

.container {
  width: 100%;
  @include box-static;
  h2 , h1 {
    @include title-section;
  }
  h1 {
    font-size: 20px;
    font-family: "Anybody-Bold";
  }
  .title {
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .toggle {
      @include toggle;
    }
  }
  .content {
    @include transition;
    overflow: hidden;
    label {
      @include label;
      color: rgba($dark, 0.7);
    }
    .bold {
      @include bold;
    }
    .section {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 0px 20px 20px 20px;
      &:not(:first-child) {
        border-top: 1px solid rgba($primary-color, 0.4);
        padding: 10px 20px 10px 20px;
      }
      .title {
        display: flex;
        padding: 0px 0px;
        h2 {
          transform: translateY(-4px);
        }
        .icon {
          svg {
            transform: translateY(5px);
            margin-right: 5px;
          }
        }
      }
      .section-content {
        @include transition;
        padding-bottom: 120px;
      }
    }
    .block {
      margin-bottom: 0px;
      .value {  
        margin-top: 5px;
      }
    }
    .list {
      margin: 20px 0px 20px 0px;
      .item {
        @include transition;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin: 0px 0px 6px 0px;
        padding: 6px 12px;
        background-color: rgba($primary-color, 0.16);
        border-radius: 8px;
        .don {
          display: flex;
          p, a {
            width: 200px;
            @include bold;
          }
          a {
            color: $primary-color;
            &:hover {
              color: $primary-color;
            }
          }
        }
        p {
          margin: 0px;
        }
        button {
          @include btn;
          color: rgba($primary-color, 0.4);
          &:hover {
            color: $danger;
          }
        }
      }
    }
  }
}