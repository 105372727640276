@import '../../styles/variables.scss';

.container {
  margin-top: 10px;
  margin-bottom: 10px;
  .file {
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
      display: flex;
      align-items: center;
      @include link;
      width: 100%;
    }
    button {
      @include btn;
      color: $primary-color;
      &:hover {
        color: $danger;
      }
    }
  }
  .image {
    width: 100%;
    a {
      display: block;
      width: 100%;
      object-fit: cover;
      margin-bottom: 6px;
      border-radius: 3px;
      img {
        width: 100%;
      }
    }
  }
  .input-file {
    margin-top: 10px;
    label {
      color: $white;
    }
  }
}