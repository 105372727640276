@import '../../../../styles/variables.scss';

.container {
  width: 100vw;
  height: 100%;
  padding: 20px;
  overflow-y: auto;
  @include scrollbar;
  z-index: 3;
  @media only screen and (min-width: $md-screen) {
    min-width: calc((100vw - 200px));
  }
  .row {
    display: flex;
    flex-direction: column;
    @media only screen and (min-width: $md-screen) {
      flex-direction: row;
      justify-content: center;
    }
    .col {
      width: 100%;
      @media only screen and (min-width: $md-screen) {
        width: 70%;
        padding: 0px 10px;
      }
      .box {
        @include box-static;
        padding: 20px;
      }
      section {
        &:not(:last-child) {
          border-bottom: 1px solid #EDECF4;
          padding-bottom: 20px;
          margin-bottom: 20px;
          padding-top: 0px;
        }
        h3 {
          @include title-section;
          margin-bottom: 20px;
        }
        label {
          @include label;
          color: rgba($dark, 0.7);
        }
        .block {
          margin-bottom: 0px;
          .value {  
            margin-top: 5px;
          }
        }
        .input-row {
          @media only screen and (min-width: $sm-screen) {
          display: flex;
          justify-content: space-between;
          width: 100%;
            .block {
              width: calc(50% - 10px);
            }
          }
        }
      }
      .types {
        padding-left: 10px;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        h3 {
          @include title-section;
        }
        button {
          @include btn;
          margin-top: 10px;
          background-color: $primary-color;
          @include text;
          color: $white;
          padding: 10px 20px;
          box-shadow: 0px 4px 20px rgba(25, 34, 67, 0.14);
          border-radius: 5px;
        }
      } 
    }
  }
}