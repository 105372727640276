@import '../../../styles/variables.scss';

.container {
  @include box-static;
  display: block;
  padding: 10px 20px;
  width: 100%;
  .title {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    h2 {
      @include title-section;
    }
    .toggle {
      @include toggle;
    }
  }
  .icon-row {
    display: flex;
    width: 100%;
    .icon {
      width: calc(100% / 6);
      display: flex;
      align-items: center;
      color: $dark;
      @include bold;
      svg {
        margin-right: 4px;
        color: $primary-color;
      }
    }
  }
  .content {
    @include transition;
    overflow: hidden;
    section {
      padding: 5px 0px;
      &:not(:first-child) {
        border-top: 1px solid #EDECF4;
        margin-top: 10px;
        padding-top: 10px;
      }
      .title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        @include bold;
        color: rgba($dark, 0.70);
        svg {
          color: $primary-color;
          margin-right: 8px;
        }
      }
      .pathologies {
        margin: 0px 0px 10px 0px;
        .pathology {
          background-color: rgba($dark, 0.08);
          margin-top: 15px;
          padding: 10px;
          border-radius: $radius;
          .name {
            @include bold;
            color: $primary-color;
            text-transform: uppercase;
            margin-bottom: 10px;
          }
          .form {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .field {
              width: calc(50% - 10px);
              p {
                margin-top: 0px;
              }
            }
          }
        }
      }
      .list {
        margin: 10px 0px 10px 0px;
        .item {
          @include transition;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin: 0px 0px 6px 0px;
          padding: 6px 12px;
          background-color: rgba($primary-color, 0.16);
          border-radius: 8px;
          .don {
            display: flex;
            p, a {
              width: 200px;
              @include bold;
            }
            a {
              color: $primary-color;
              &:hover {
                color: $primary-color;
              }
            }
          }
          .bold {
            @include bold;
          }
          p {
            margin: 0px;
          }
          button {
            @include btn;
            color: rgba($primary-color, 0.4);
            &:hover {
              color: $danger;
            }
          }
        }
      }
    }
  }
}