@import '../../../../../styles/variables.scss';

.container {
  padding: 20px;
  overflow-y: auto;
  max-height: calc(100vh - 40px);
  @include scrollbar;
  min-width: calc(100% - 40px);
  @media only screen and (min-width: $md-screen) {
    min-width: 600px;
  }
  h2 {
    @include title-section;
    margin: 0px 0px 20px 0px;
  }
  label {
    @include label;
    color: rgba($dark, 0.7);
  }
  .input {
    margin-top: 7px;
  }
  .actions {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    button {
      @include btn;
      @include text-button;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      border : none;
      position: relative;
      color: $white;
      background: $primary-color;
      line-height: 29px;
      border-radius: $radius;
      letter-spacing: 1px;
      cursor: pointer;
      min-width: 155px;
      padding: 6px 20px;
      &:hover {
        background-color: $success;
      }
      &.delete {
        background-color: transparent;
        color: $danger;
        &:hover {
          background-color: $danger;
          color: $white;
        }
      }
    }
  }
}