.container-switch{

  label{
    display: block;
    font-size: 14px;
  }

  label+.switch{
    margin-top: 10px;
  }

  .switch{
    .react-switch-bg{
      background-color: red;
    }
  }
}
