@import '../../../../styles/variables.scss';

.container {
  margin: 0px auto 20px auto;
  width: calc(100% - 40px);
  @include box-static;
  padding: 15px 20px;
  @media only screen and (min-width: $md-screen) {
    width: 80%;
  }
  h1 {
    margin: 0px;
  }
  h2 {
    @include title-section;
    margin: 0px;
  }
  .border {
    @include border;
  }
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .toggle {
      @include toggle;
    }
  }
  .content {
    @include transition;
    overflow: hidden;
    label {
      @include label;
      color: rgba($dark, 0.7);
    }
    .bold {
      @include bold;
    }
    .row {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 10px;
      @media only screen and (min-width: $md-screen) {
        flex-direction: row;
        margin-top: 0px;
      }
      .col {
        flex: 1;
        &.satisfaction {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          p {
            @include text;
            margin-left: 10px;
            min-width: 100px;
            text-align: center;
            background-color: $primary-color;
            color: $white;
            box-shadow: 0px 4px 20px rgba(25, 34, 67, 0.14);
            border-radius: 5px;
            padding: 6px 10px;
          }
        }
        &.icon {
          display: flex;
          align-items: center;
          svg {
            color: $primary-color;
            margin-right: 10px;
            transform: translateY(-2px);
          }
        }
      }
    }
    .text {
      margin-top: 20px;
    }
    .list {
      @include list;
      margin-bottom: 10px;
      .item {
        margin: 8px 0px;
      }
      a {
        @include transition;
        display: block;
        background-color: rgba($primary-color, 0.2);
        padding: 6px 10px;
        border-radius: $radius;
        color: $dark;
        margin: 8px 0px;
        &:hover {
          background-color: rgba($primary-color, 0.3);
        }
      }
    }
  }
}