@import '../../styles/variables.scss';

.container {
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  pointer-events: none;
  * {
    pointer-events: all;
  }
  &.hide {
    display: none;
  }
  &.dashboard {
    .logo {
      display: none;
      @media only screen and (min-width: $md-screen) {
        display: flex;
      }
    }
    .menu {
      span {
        background: $white;
      }
    }
  }
  @media only screen and (min-width: $md-screen) {
    width: 200px;
    background-color: #FFFFFF;
    box-shadow: 4px 7px 38px rgba(0, 0, 0, 0.08);
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
  }
  .logo {
    position: absolute;
    top: 0px;
    width: 100%;
    left: -2px;
    display: flex;
    justify-content: center;
    align-items: center;
    h2 {  
      @include title-2;
      color: $dark;
    }
    img {
      max-height: 15px;
      margin-right: 8px;
    }
  }
  .menu {
    @include btn;
    z-index: 4;
    position: absolute;
    pointer-events: all;
    display:flex;
    flex-direction:column;
    width: 30px;
    top: 12px;
    left: 20px;
    cursor:pointer;

      span {
        background: $primary-color;
        border-radius:10px;
        height:3px;
        margin: 3px 0;
        width: 100%;
        transition: .4s  cubic-bezier(0.68, -0.6, 0.32, 1.6);
      }

      &.close {
        span:nth-of-type(1){
          transform-origin: center;
          transform: translate(-6px , 6px) rotate(45deg) ;
        }
        span:nth-of-type(2){
          transform-origin: bottom;
          transform: translate(-6px , -3px) rotate(-45deg) ;
        }
        span:nth-of-type(3){
          transform-origin: bottom;
          background: transparent;
          transform:rotate(45deg);
        }
      }

    @media only screen and (min-width: $md-screen) {
      display: none;
    }
  }
  .nav {
    width: 100%;
    position: absolute;
    background-color: #FFFFFF;
    top: 52px;
    height: calc(100% - 52px);
    transform: translateX(-100%);
    @media only screen and (min-width: $md-screen) {
      transform: translateX(0px);
      background-color: transparent;
    }
    @include transition;
    &.is-active {
      transform: translateX(0px);
    }
    ul {
      list-style: none;
      padding: 0px;
      height: calc(100% - 110px);
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      @include scrollbar;
      @media only screen and (min-height: 700px) {
        justify-content: center;
      }
      .separator {
        border-bottom: 1px solid #EDECF4;
        width: calc(100% - 40px);
        margin: 10px 20px;
      }
      li {
        width: 100%;
        font-size: 20px;
        @media only screen and (min-width: $md-screen) {
          border-bottom: 1px solid transparent;
        }
        @include transition;
        &:hover {
          background-color: rgba($primary-color , 0.1);
        }
        @include text;
        a {
          text-decoration: none;
          padding: 10px 20px;
          color: $grey;
          display: flex;
          align-items: center;
          
          @include transition;
          .puce {
            @include transition;
            width: 7px;
            height: 7px;
            border-radius: 7px;
            background: #D2CFE3;
            opacity: 0.4;
            margin-right: 8px;
          }
          &.active-link {
            @include bold;
            .puce {
              background-color: $primary-color;
              opacity: 1;
            }
          }
        }
      }
    }
    .profile {
      button {
        @include btn;
      }
      padding-bottom: 20px;
      .name {
        padding: 0px 20px;
        display: flex;
        align-items: center;
        @include bold;
      }
      .logout {
        @include text;
        padding: 0px 20px;
        display: flex;
        align-items: center;
        color: $grey;
        text-decoration: underline;
        img {
          margin-left: 10px;
        }
      }
    }
  }
} 