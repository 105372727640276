@import '../../../styles/variables.scss';
@import '../form-mixin.scss';

.container-input{

  label{
    @include label;
  }

  &.inline {
    @include inline;
  }

  &:not(.inline){
    label+.input {
      margin-top: 10px;
    }
  }

  .input {
    @include input;
  }
  &.primary {
    @include primary;
    input {
      &::-webkit-calendar-picker-indicator {
        color: $dark;
      }
    }
  }
  &.login {
    @include login;
  }

}
