@import '../../styles/variables.scss';

.container {
  @include transition;
  width: 100%;
  background: $white;
  border: 1px solid rgba(79, 60, 200, 0.31);
  box-shadow: 0px 4px 20px rgba(25, 34, 67, 0.14);
  border-radius: 5px;
  margin-top: 20px;
  pointer-events: none;
  &.is-edit {
    cursor: pointer;
    pointer-events: all;
  }
  &:hover {
    transform: scale(1.02);
    box-shadow: 0px 8px 10px rgba(25, 34, 67, 0.14);
  }
  .text {
    padding: 0px 20px;
  }
  .row {
    display: flex;
    flex-direction: column;
    @media only screen and (min-width: $md-screen) {
      flex-direction: row;
      border-top: 1px solid rgba(79, 60, 200, 0.31);
    }
    p {
      margin: 0px;
    }
    .col {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 8px 12px;
      border-top: 1px solid rgba(79, 60, 200, 0.15);
      @media only screen and (min-width: $md-screen) {
        width: 25%;
        border-top: 1px solid transparent;
        border-right: 1px solid rgba(79, 60, 200, 0.31);
        &:first-child {
          width: 15%;
        }
      }
      svg {
        color: $primary-color;
        margin-right: 5px;
      }
      span {
        @include bold;
        color: $primary-color;
      }
    }
    .status {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 6px 8px;
      border-top: 1px solid rgba(79, 60, 200, 0.15);
      @media only screen and (min-width: $md-screen) {
        justify-content: space-between;
        border-top: 1px solid transparent;
        width: 60%;
      }
      .priority {
        height: 30px;
        width: 150px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        span {
          color: rgba($dark, 0.5);
        }
        .level-1 {
          width: 16px;
          height: 16px;
          border-radius: 10px;
          margin-right: 6px;
          background-color: $danger;
        }
        .level-2 {
          width: 16px;
          height: 16px;
          border-radius: 10px;
          margin-right: 6px;
          background-color: #fb9b60;
        }
        .level-3 {
          width: 16px;
          height: 16px;
          border-radius: 10px;
          margin-right: 6px;
          background-color: #ffd16d;
        }
      }
      .state {
        height: 30px;
        width: 80px;
        margin-left: 8px;
        border-radius: 5px;;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        &.todo {
          background-color: rgba($dark, 0.25);
        }
        &.doing {
          background-color: #FFA56D;
        }
        &.done {
          background-color: $success;
        }
      }
    }
  }
}