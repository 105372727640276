@import '../../../../styles/variables.scss';

.container {
  margin: 0px auto 20px auto;
  width: calc(100% - 40px);
  @include box-static;
  padding: 15px 20px;
  @media only screen and (min-width: $md-screen) {
    width: 80%;
  }
  h2 {
    @include title-section;
    margin: 0px;
  }
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .toggle {
      @include toggle;
    }
  }
  .content {
    @include transition;
    overflow: hidden;
    label {
      @include label;
      color: rgba($dark, 0.7);
    }
    .bold {
      @include bold;
    }
    .list {
      margin-bottom: 30px;
      margin-top: 10px;
      .meeting-row {
        display: flex;
        border-top: 1px solid #EDECF4;
        margin-top: 20px;
        padding-top: 20px;
        .name {
          width: 20%;
          padding-right: 20px;
          border-right: 1px solid #EDECF4;
          p {
            margin: 0px;
            @include bold;
          }
        }
        .meeting {
          width: 80%;
          display: flex;
          flex-direction: column;
          padding-left: 20px;
          .row {
            margin-bottom: 20px;
          }
          .col {
            width: 100%;
            &.icon {
              display: flex;
              align-items: center;
              p {
                margin: 0px;
              }
              svg {
                color: $primary-color;
                margin-right: 10px;
                transform: translateY(-2px);
              }
              &.user {
                margin-top: 10px ;
              }
            }
          }
        }
      }
    }
  }
}