@import '../../../styles/variables.scss';

.container {
  @include page-container;
  display: flex;
  flex-direction: column;
  header {
    padding: 0px 20px;
    @media only screen and (min-width: $md-screen) {
      display: flex;
      justify-content: space-between;
      padding: 20px 40px;
    }
    .title {
      h2 {
        @include title-2;
        margin-bottom: 10px;
        text-transform: uppercase;
      }
      .link {
        @include back-link;
      }
    }
    .btn-container {
      width: 100%;
      @media only screen and (min-width: $md-screen) {
        width: auto;
        display: flex;
        justify-content: flex-end;
      }
      button {
        width: 100%;
        @media only screen and (min-width: $md-screen) {
          width: 220px;
          min-height: 57px;
        }
      }
      a {
        text-decoration: none;
      }
      .edit {
        @include edit-btn;
      }
    }
  }
  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-top: 10px;
    width: 100%;
    height: calc(100vh - 130px);
    overflow-y: auto;
    @include scrollbar;
    z-index: 3;
    @media only screen and (min-width: $md-screen) {
      padding: 5px 40px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }
    label {
      @include label;
      color: rgba($dark, 0.7);
    }
    .col {
      width: 100%;
      @media only screen and (min-width: $md-screen) {
        width: calc(60% - 10px);
        &.right {
          width: calc(40% - 10px);
          .infos {
            flex-direction: column
          }
        }
      }
      .infos {
        @include box-static;
        padding: 20px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        .name {
          display: flex;
          align-items: center;
          .icon {
            width: 23px;
            height: 23px;
            border-radius: 15px;
            margin-right: 5px;
          }
          h1 {
            @include title-2;
            margin: 0px;
            text-transform: uppercase;
          }
        }
        p {
          margin: 0px;
          @include bold;
        }
        h3 {
          @include title-2;
          margin: 0px;
          font-size: 16px;
          color: $primary-color;
          text-transform: uppercase;
        }
        .centers {
          @include list;
          width: 100%;
          margin: 20px 0px 0px 0px;
          .item {
            text-decoration: none;
            @include bold;
            color: $primary-color;
            &:hover {
              background-color: rgba($primary-color, 0.3);
            }
          }
        }
      }      
    }
  }
}