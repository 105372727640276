@import '../../../styles/variables.scss';

.container {
  @include page-container;
  display: flex;
  flex-direction: column;
  header {
    padding: 0px 20px;
    @media only screen and (min-width: $md-screen) {
      display: flex;
      justify-content: space-between;
      padding: 20px 40px;
    }
    .title {
      h2 {
        @include title-2;
        margin-bottom: 10px;
        text-transform: uppercase;
      }
      .link {
        @include back-link;
      }
    }
    .btn-container {
      width: 100%;
      @media only screen and (min-width: $md-screen) {
        width: auto;
        display: flex;
        justify-content: flex-end;
      }
      button {
        width: 100%;
        @media only screen and (min-width: $md-screen) {
          width: 220px;
          min-height: 57px;
        }
      }
      .edit {
        @include edit-btn;
      }
    }
  }
  .nav {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 5px;
    @media only screen and (min-width: $md-screen) {
      position: relative;
    }
    ul {
      width: calc(100% - 40px);
      display: flex;
      justify-content: space-between;
      background: rgba(210, 207, 227, 0.24);
      border-radius: 5px;
      padding: 8px;
      overflow-x: scroll;
      -ms-overflow-style: none; /* for Internet Explorer, Edge */
      scrollbar-width: none; /* for Firefox */
      &::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
      }
      @media only screen and (min-width: $md-screen) {
        overflow-x: hidden;
        width: calc(200px * 5);
      }
      li {
        list-style: none;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        min-width: 190px;
        &:last-child {
          margin-right: 190px;
        }
        @media only screen and (min-width: $md-screen) {
          width: 190px;
          min-width: 190px;
          &:last-child {
            margin-right: 0px;
          }
        }
        text-align: center;
        @include bold;
        color: rgba($dark, 0.7);
        cursor: pointer;
        @include transition;
        &.active-form {
          background-color: $white;
          box-shadow: 0px 10px 28px #EBECED;
          border-radius: 5px;
        }
        &:hover {
          background-color: rgba($white, 0.7);
          border-radius: 5px;
        }
      }
    }
  }
  .content {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    overflow: hidden;
    height: calc(100vh - 200px);
    @media only screen and (min-width: $md-screen) {
      height: calc(100vh - 130px);
    }
    .form-container {
      display: flex;
      width: calc(100% * 5);
      height: 100%;
      transition: all 0.6s ease-in-out;
      @media only screen and (min-width: $md-screen) {
        width: calc((100vw - 200px) * 5);
      }
      .form {
        width: 100vw;
        height: 100%;
        padding: 20px;
        overflow-y: auto;
        @include scrollbar;
        z-index: 3;
        @media only screen and (min-width: $md-screen) {
          width: calc((100vw - 200px) * 5);
        }
      }
    }
  }
}