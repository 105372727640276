@import '../../../../styles/variables.scss';

.container {
  width: 100vw;
  height: 100%;
  padding: 20px;
  overflow-y: auto;
  @include scrollbar;
  z-index: 3;
  @media only screen and (min-width: $md-screen) {
    width: calc((100vw - 200px) * 5);
  }
  .row {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .col {
      width: 100%;
      @media only screen and (min-width: $md-screen) {
        width: calc(70% - 5px);
        padding: 0px 10px;
      }
      .box {
        @include box-static;
        padding: 20px;
      }
      label {
        color: rgba($dark , 0.6);
      }
      .octapeoples {
        margin-top: 10px;
        width: 100%;
        .row {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
          padding: 8px;
          border-radius: 5px;
          &.label {
            color: rgba($dark , 0.6);
          }
          &:not(.label) {
            @include bold;
          }
          &:nth-child(even) {
            background-color: rgba($dark , 0.06);
          }
          .col {
            width: 25%;
            padding: 0px;
          }
          .name {
            width: 50%;
            padding: 0px;
          }
        }
      }
      .guests {
        margin-top: 10px;
        width: 100%;
        .row {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
          padding: 8px;
          border-radius: 5px;
          &.label {
            color: rgba($dark , 0.6);
          }
          &:not(.label) {
            @include bold;
          }
          &:nth-child(even) {
            background-color: rgba($dark , 0.06);
          }
          .col {
            width: 20%;
            padding: 0px;
            &:last-child {
              width: 10%;
            }
          }
          .name {
            width: 25%;
            padding: 0px;
          }
        }
      }
      .trips {
        .row {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          &.label {
            color: rgba($dark , 0.6);
          }
          &:not(.label) {
            border-top: 1px solid rgba($primary-color, 0.3);
            border-left: 1px solid rgba($primary-color, 0.3);
            border-right: 1px solid rgba($primary-color, 0.3);
            &:last-child { 
              border-bottom: 1px solid rgba($primary-color, 0.3);
            }
            .col {
              &:not(:last-child) { 
                border-right: 1px solid rgba($primary-color, 0.3);
              }
            }
          }
          .col {
            width: 33.33%;
            min-height: 100%;
            padding: 8px;
            p {
              margin: 0px;
              vertical-align: middle;
              display: flex;
              align-items: center;
              svg {
                color: $primary-color;
                font-size: 20px;
                margin: 0px 5px;
              }
              span {
                opacity: 0.2;
              }
            }
          }
        }
      }
    }
  }
}