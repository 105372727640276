@import '../../../styles/variables.scss';

.container {
  @include transition;
  width: 100%;
  box-shadow: $shadow;
  border-radius: $radius;
  text-decoration: none;
  &:hover {
    transform: scale(1.02);
  }
  .image {
    height: 120px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-top-left-radius: $radius;
    border-top-right-radius: $radius;
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  .infos {
    padding: 20px 20px 0px 20px;
    color: rgba($dark, 0.7);
    overflow: hidden;
    height: 130px;
    h4 {
      margin: 0px 0px 10px 0px;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 16px;
    }
    p {
      font-size: 14px;
      margin: 0px;
      text-overflow: ellipsis;
    }
  }
  .dates {
    display: flex;
    justify-content: flex-start;
    padding: 15px 20px;
    &.news {
      justify-content: space-between;
    }
    .author {
      @include bold;
    }
    .date {
      display: flex;
      align-items: center;
      color: rgba($dark, 0.7);
      margin-right: 15px;
      svg {
        font-size: 18px;
        margin-right: 10px;
      }
      p {
        margin: 0px;
      }
    }
  }
}