@import '../../../styles/variables.scss';

.container {
  @include page-container;
  display: flex;
  flex-direction: column;
  header {
    padding: 0px 20px;
    @media only screen and (min-width: $md-screen) {
      display: flex;
      justify-content: space-between;
      padding: 20px 40px;
    }
    .title {
      h2 {
        @include title-2;
        margin-bottom: 10px;
        text-transform: uppercase;
        display: none;
        @media only screen and (min-width: $md-screen) {
          display: block;
        }
      }
      .link {
        @include back-link;
      }
    }
    .update-date {
      position: absolute;
      right: 240px;
      top: 25px;
      color: $primary-color;
      font-style: italic;
      opacity: 0.6;
      span {
        @include bold;
        font-style: normal;
        color: $primary-color;
      }
      display: none;
      @media only screen and (min-width: $md-screen) {
        display: block;
      }
    }
    .btn-container {
      width: 100%;
      @media only screen and (min-width: $md-screen) {
        width: auto;
        display: flex;
        justify-content: flex-end;
      }
      button {
        width: 100%;
        @media only screen and (min-width: $md-screen) {
          width: 220px;
          min-height: 57px;
        }
      }
      a {
        text-decoration: none;
      }
      .edit {
        @include edit-btn;
      }
    }
  }
  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: calc(100vh - 75px);
    padding-top: 20px;
    overflow: auto;
    @include scrollbar;
    @media only screen and (min-width: $md-screen) {
      height: calc(100vh - 107px);
    }
  }
}