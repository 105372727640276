@import '../../../../../styles/variables.scss';

.container {
  display: flex;
  .select {
    width: 15%;
    min-width: 15%;
  }
  .field {
    width: 20%;
    min-width: 20%;
    border: 1px solid rgba(79, 60, 200, 0.3);
    overflow: hidden;
  }
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    @include btn;
    margin: 0px 0px 0px 6px;
    color: rgba($primary-color, 0.4);
    &:hover {
      color: $danger;
    }
  }
}