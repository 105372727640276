@import '../../../../styles/variables.scss';

.container {
  width: 100vw;
  height: 100%;
  padding: 0px 20px;
  overflow-y: auto;
  @include scrollbar;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (min-width: $md-screen) {
    width: calc((100vw - 200px));
  }
  .btn {
    width: 100%;
    margin: 20px 0px;
    @media only screen and (min-width: $md-screen) {
      width: 70%;
      padding: 0px 10px;
      margin: 0px 0px 20px;
    }
  }
  .row {
    display: flex;
    flex-direction: column;
    width: 100%;
    @media only screen and (min-width: $md-screen) {
      width: 70%;
      padding: 0px 10px;
    }
  }
}