@import '../../../../../styles/variables.scss';

.container {
  margin-bottom: 20px;
  @include box-static;
  .title {
    display: flex;
    width: 100%;
    padding: 10px 15px;
    justify-content: space-between;
    align-items: center;
    h2 {
      @include title-1;
      margin: 0px;
      font-size: 20px;
      color: $primary-color;
    }
    .actions {
      display: flex;
      align-items: center;
      .delete {
        @include btn;
        margin-right: 8px;
        svg {
          font-size: 20px;
          color: $primary-color;
          &:hover {
            color: $danger;
          }
        }
      }
      .toggle {
        @include btn;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $primary-color;
        width: 28px;
        height: 28px;
        border-radius: 15px;
        &:hover {
          background-color: rgba($primary-color, 0.2);
        }
        &.open {
          transform: rotate(180deg);
        }
      }
    }
  }
  .section-content {
    @include transition;
    padding-bottom: 0px;
    .input-list {
      display: flex;
      flex-direction: column;
      padding: 15px;
      .label {
        @include bold;
        color: rgba($dark, 0.7);
      }
      label {
        @include label;
        color: rgba($dark, 0.7);
      }
      .block {
        margin-bottom: 10px;
        .value {  
          margin-top: 5px;
        }
      }
    }
  }
}