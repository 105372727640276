@import './variables.scss';

body, html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  overscroll-behavior: none;
  scroll-behavior: smooth;
  color: $black;
  font-family: "Ubuntu-Regular" , -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $bg-color;
  box-sizing: border-box;
  font-size: 14px;
}

* {
  box-sizing: border-box;
}

input {
  color-scheme: $primary-color;
}

sup { vertical-align: top; position: relative; top: -0.5em; }

.app-container {
  position: relative;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 0;
  overflow: hidden;
}

.form-group {
  @include form-group;
}

.DraftEditor-editorContainer {
  z-index: 0 !important;
}

.public-DraftStyleDefault-ltr {
  margin-bottom: 10px;
}