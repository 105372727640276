@import '../../../../styles/variables.scss';

.container {
  width: 100vw;
  height: 100%;
  padding: 20px;
  overflow-y: auto;
  @include scrollbar;
  z-index: 3;
  @media only screen and (min-width: $md-screen) {
    width: calc((100vw - 200px) * 5);
  }
  .row {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .col {
      width: 100%;
      @media only screen and (min-width: $md-screen) {
        width: calc(70% - 5px);
        padding: 0px 10px;
      }
      .box {
        @include box-static;
        padding: 20px;
      }
      section {
        margin-top: 20px;
        &:not(:last-child) {
          border-bottom: 1px solid #EDECF4;
          padding-bottom: 20px;
          margin-bottom: 20px;
          padding-top: 0px;
        }
        h3 {
          @include title-section;
          margin: 0px 0px 10px 0px;
        }
        h3 {
          @include bold;
          margin: 0px 0px 10px 0px;
        }
        label {
          @include label;
          color: rgba($dark, 0.7);
          span {
            font-size: 11px;
          }
        }
        .file-section {
          @include file-section;
          margin-bottom: 10px;
        }
        .input-block {
          margin-bottom: 10px;
          .input {  
            margin-top: 5px;
          }
        }
      }
      .meals {
        margin-top: 10px;
        .item {
          @include transition;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
          margin: 0px 0px 6px 0px;
          padding: 6px 12px;
          background-color: rgba($primary-color, 0.16);
          border-radius: 8px;
          cursor: pointer;
          p {
            @include bold;
            min-width: 20%;
            margin: 0px;
          }
          &:hover {
            background-color: rgba($primary-color, 0.30);
          }
        }
      }
      .list {
        margin-top: 10px;
        cursor: pointer;
        .card {
          margin-bottom: 10px;
          position: relative;
          background-color: #FAFBFB;
          border: 1px solid rgba($primary-color, 0.3);  
          display: flex;
          .content {
            padding: 15px;
            width: 100%;
            position: relative;
            label {
              opacity: 0.8;
              display: block;
              svg {
                color: $primary-color;
                margin-right: 5px;
                font-size: 16px;
                transform: translateY(2px);
              }
            }
            .hour {
              max-width: 200px;
              display: flex;
              justify-content: space-between;
            }
            .address {
              margin-bottom: 8px;
              min-height: 34px;
            }
            p {
              margin: 0px 0px 5px 0px;
            }
            a {
              margin-top: 15px;
              display: inline-block;
              background-color: rgba($primary-color, 0.3);
              color: $primary-color;
              text-decoration: none;
              padding: 6px 10px;
              border-radius: $radius;
            }
            button {
              @include btn;
              position: absolute;
              right: 15px;
              top: 10px;
              color: rgba($primary-color, 0.8)
            }
          }
          .plan {
            width: 40%;
            max-height: 200px;
            object-fit: cover;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            img {
              width: 100%;
            }
          }
        }
      }
      .add-btn {
        @include btn-primary;
      }
    }
  }
}