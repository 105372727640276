@import '../../../../styles/variables.scss';

.container {
  width: 100vw;
  height: 100%;
  padding: 20px;
  overflow-y: auto;
  @include scrollbar;
  z-index: 3;
  @media only screen and (min-width: $md-screen) {
    width: calc((100vw - 200px) * 5);
  }
  .row {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .col {
      width: 100%;
      @media only screen and (min-width: $md-screen) {
        width: calc(90% - 5px);
        padding: 0px 10px;
      }
      .box {
        @include box-static;
        padding: 20px;
      }
      .input-file {
        margin-top: 10px;
        margin-bottom: 20px;
        input {
          display: none;
        }
        label {
          @include btn-primary;
        }
      }
      .loading {
        margin-top: 4px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        color: $primary-color;
        .loader {
          width: 20px;
          height: 20px;
          margin-right: 10px;
          border: 2px solid $primary-color;
          border-bottom-color: transparent;
          border-radius: 50%;
          display: inline-block;
          box-sizing: border-box;
          animation: rotation 1s linear infinite;
        }
        @keyframes rotation {
          0% {
              transform: rotate(0deg);
          }
          100% {
              transform: rotate(360deg);
          }
        } 
      }
      section {
        &:not(:last-child) {
          border-bottom: 1px solid #EDECF4;
          padding-bottom: 20px;
          margin-bottom: 20px;
          padding-top: 0px;
        }
        h3 {
          @include title-section;
          margin: 0px 0px 10px 0px;
        }
        label {
          @include label;
          color: rgba($dark, 0.7);
          span {
            font-size: 11px;
          }
        }
        .add-btn {
          @include btn-primary;
        }
      }
      .file-section {
        @include file-section;
      }
    }
  }
}