@import '../../../styles/variables.scss';
@import '../form-mixin.scss';

.container{
  z-index: -1;
  margin: 0;
  color: $dark;
  .container-text {
    border-radius: $radius;
    min-height: 140px;
    padding: 8px 10px;
    min-width: 100px;
    margin-bottom: 20px;
    background: #FAFBFB;
    border: 1px solid rgba(79, 60, 200, 0.3);
    color: $dark;
    &:focus{
      border: 1px solid rgba(79, 60, 200, 1);
    }
    p {
      z-index: -0;
      margin: 5px 0px;
    }
  }
}