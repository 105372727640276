@import '../../../../styles/variables.scss';

.container {
  width: 100vw;
  height: 100%;
  padding: 20px;
  overflow-y: auto;
  @include scrollbar;
  z-index: 3;
  @media only screen and (min-width: $md-screen) {
    width: calc((100vw - 200px) * 5);
  }
  .row {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .col {
      width: 100%;
      @media only screen and (min-width: $md-screen) {
        width: calc(70% - 5px);
        padding: 0px 10px;
      }
      .box {
        @include box-static;
        padding: 20px;
      }
      h3 {
        @include title-section;
        margin: 0px 0px 10px 0px;
      }
      label {
        @include label;
        color: rgba($dark, 0.7);
        span {
          font-size: 11px;
        }
      }
      .input-block {
        margin-bottom: 10px;
        .input {  
          margin-top: 5px;
          position: relative;
          .unit {
            position: absolute;
            right: 10px;
            top: 8px;
            @include label;
            color: rgba($dark, 0.7);
          }
        }
      }
      .input-row {
        @media only screen and (min-width: 1270px) {
          display: flex;
          justify-content: space-between;
          .input-block {
            width: calc(50% - 10px);
          }
        }
      }
      .input-boolean {
        margin-top: 10px;
        @media only screen and (min-width: 1270px) {
          display: flex;
          .input-block {
            width: calc(33% - 10px);
          }
        }
      }
      .list {
        margin-top: 10px;
        .item {
          @include transition;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin: 0px 0px 6px 0px;
          padding: 6px 12px;
          background-color: rgba($primary-color, 0.16);
          border-radius: 8px;
          .col2 {
            width: 90%;
            display: flex;
            p {
              width: 50%;
              &.date {
                width: 75%;
              }
            }
          }
          .col3 {
            width: 90%;
            display: flex;
            p {
              width: 33%;
            }
          }
          p {
            margin: 0px;
          }
          button {
            @include btn;
            color: rgba($primary-color, 0.4);
            &:hover {
              color: $danger;
            }
          }
        }
      }
      .file-section {
        margin-bottom: 10px;
        margin-top: 20px;
        .file {
          display: flex;
          justify-content: space-between;
          align-items: center;
          a {
            display: flex;
            align-items: center;
            text-decoration: none;
            padding: 6px 10px;
            background-color: rgba($primary-color ,0.1);
            width: calc(100% - 30px);
            margin: 0px 0px 6px 0px;
            border-radius: $radius;
            @include bold;
            color: $primary-color;
            @include transition;
            &:hover {
              background-color: rgba($primary-color ,0.2);
            }
          }
          button {
            @include btn;
            color: $primary-color;
            &:hover {
              color: $danger;
            }
          }
        }
        .input-file {
          width: 100%;
          margin: 10px 0px 30px 0px;
          .input {
            margin-top: 20px;
            display: flex;
            justify-content: flex-start;
          }
          label {
            color: $white;
          }
        }
      }
      .symposium-list {
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .card {
          padding: 15px;
          width: 100%;
          margin-bottom: 10px;
          position: relative;
          background-color: #FAFBFB;
          border: 1px solid rgba($primary-color, 0.3);
          label {
            opacity: 0.8;
            display: block;
            svg {
              color: $primary-color;
              margin-right: 5px;
              font-size: 16px;
              transform: translateY(2px);
            }
          }
          .address {
            margin-bottom: 8px;
            min-height: 34px;
          }
          p {
            margin: 0px 0px 5px 0px;
          }
          button {
            @include btn;
            position: absolute;
            right: 15px;
            top: 10px;
            color: rgba($primary-color, 0.8)
          }
        }
      }
      .add-btn {
        @include btn-primary;
      }
    }
  }
}