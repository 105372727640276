@import '../../../../styles/variables.scss';

.container {
  width: 100vw;
  height: 100%;
  padding: 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  @include scrollbar;
  z-index: 3;
  @media only screen and (min-width: $sm-screen) {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }
  @media only screen and (min-width: $md-screen) {
    width: calc(100vw - 200px);
  }
  .box {
    @include box-static;
    padding: 20px;
  }
  h3 {
    @include title-section;
    margin: 0px 0px 10px 0px;
  }
  label {
    @include label;
    color: rgba($dark, 0.7);
    &.bold {
      @include bold;
      margin-top: 10px;
      display: inline-block;
      color: rgba($dark, 0.7);
    }
  }
  .form-group {
    @include form-group;
    margin-bottom: 20px;
  }
  main {
    width: 100%;
    margin-bottom: 20px;
    @media only screen and (min-width: $sm-screen) {
      width: 85%;
    }
    @media only screen and (min-width: $lg-screen) {
      width: 70%;
    }
    .quantity-row {
      display: flex;
      flex-direction: column;
      width: 100%;
      border-bottom: 1px solid #EDECF4;
      margin-bottom: 10px;
      @media only screen and (min-width: $md-screen) {
        flex-direction: row;
        justify-content: space-between;
        .form-group {
          width: calc(33.33% - 10px);
        }
      }
    }
    .list {
      @include list;
      .composition {
        width: 20%;
        @include bold;
      }
    }
  }
}