@import '../../styles/variables.scss';

.container {
  margin-bottom: 20px;
  .title {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    .toggle {
      @include btn;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $primary-color;
      width: 28px;
      height: 28px;
      border-radius: 15px;
      &:hover {
        background-color: rgba($primary-color, 0.2);
      }
      &.open {
        transform: rotate(180deg);
      }
    }
  }
  &.nested {
    margin-bottom: 0px;
    &:first-child {
      margin-top: 10px;
    }
    &:not(:last-child) {
      border-bottom: 1px solid #E1E1E1;
      padding-bottom: 10px;
      margin-bottom: 10px;
    }
    .title {
      justify-content: flex-start;
    }
    .label {
      @include label;
      @include bold;
      text-transform: none;
      color: rgba($dark, 0.7);
    }
    .toggle {
      color: rgba($dark, 0.7);
      margin-left: 10px;
      width: 22px;
      height: 22px;
      &:hover {
        background-color: rgba($dark, 0.1);
      }
    } 
  }
  &:not(.nested) {
    @include box-static;
    padding: 15px 20px;
    h2 {
      @include title-section;
    }
  }
  .section-content {
    @include transition;
    padding-bottom: 0px;
  }
}