@import '../../../../styles/variables.scss';

.container {
  width: 100vw;
  height: 100%;
  padding: 20px;
  overflow-y: auto;
  @include scrollbar;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (min-width: $md-screen) {
    padding: 0px 20px;
    width: calc((100vw - 200px));
  }
  .row {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px 10px;
    width: 100%;
    @media only screen and (min-width: $sm-screen) {
      flex-direction: row;
    }
    .select {
      @include box-static;
      width: 100%;
      padding: 10px;
      margin-right: 20px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      @media only screen and (min-width: $sm-screen) {
        width: 20%;
        flex-direction: column;
        flex-wrap: nowrap;
      }
      button {
        @include btn;
        @include label;
        @include bold;
        font-size: 14px;
        background-color: rgba($primary-color , 0.16);
        padding: 5px 12px ;
        border-radius: 5px;
        color: rgba($dark , 0.6);
        margin-right: 10px;
        text-align: left;
        &:not(:last-child) {
          margin-bottom: 10px;
        }
        @media only screen and (min-width: $sm-screen) {
          width: 100%;
          margin-right: 0px;
        }
        &:hover {
          background-color: rgba($primary-color , 0.4);
        }
        &.active {
          color: $white;
          background-color: $primary-color;
        }
      }
    }
    .col {
      padding: 10px;
      width: 100%;
      @media only screen and (min-width: $sm-screen) {
        width: 60%;
      }
      @media only screen and (min-width: $sm-screen) {
        width: calc(80% - 20px);
      }
      .add {
        margin-bottom: 20px;
      }
    }
  }
}