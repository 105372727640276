@import '../../../../styles/variables.scss';

.container {
  margin-bottom: 0px;
  .title {
    display: flex;
    width: 100%;
    padding: 10px 15px;
    justify-content: space-between;
    align-items: center;
    h2 {
      @include bold;
      margin: 0px;
      font-size: 16px;
      color: $primary-color;
    }
    .toggle {
      @include btn;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $primary-color;
      width: 28px;
      height: 28px;
      border-radius: 15px;
      &:hover {
        background-color: rgba($primary-color, 0.2);
      }
      &.open {
        transform: rotate(180deg);
      }
    }
  }
  .section-content {
    @include transition;
    padding-bottom: 0px;
    .block {
      padding: 10px 20px;
    }
    h3 {
      margin: 0px;
      padding: 10px 20px;
    }
  }
}