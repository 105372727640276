@import '../../../../styles/variables.scss';

.container {
  width: 100vw;
  height: 100%;
  padding: 20px;
  overflow-y: auto;
  @include scrollbar;
  z-index: 3;
  @media only screen and (min-width: $md-screen) {
    width: calc((100vw - 200px) * 5);
  }
  .row {
    display: flex;
    flex-direction: column;
    @media only screen and (min-width: $md-screen) {
      flex-direction: row;
      justify-content: space-between;
    }
    .col {
      width: 100%;
      @media only screen and (min-width: $md-screen) {
        width: calc(50% - 5px);
        padding: 0px 10px;
      }
      .box {
        @include box-static;
        padding: 20px;
      }
      section {
        &:not(:last-child) {
          border-bottom: 1px solid #EDECF4;
          padding-bottom: 20px;
          margin-bottom: 20px;
          padding-top: 0px;
        }
        h3 {
          @include title-section;
          margin: 0px 0px 10px 0px;
        }
        label {
          @include label;
          color: rgba($dark, 0.7);
        }
        .input-block {
          margin-bottom: 10px;
          .input {  
            margin-top: 5px;
          }
        }
        .input-row {
          @media only screen and (min-width: 1270px) {
          display: flex;
          justify-content: space-between;
            .input-block {
              width: calc(50% - 10px);
            }
          }
        }
        .list {
          margin-top: 10px;
          .item {
            @include transition;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin: 0px 0px 6px 0px;
            padding: 6px 12px;
            background-color: rgba($primary-color, 0.16);
            border-radius: 8px;
            .col2 {
              width: 90%;
              display: flex;
              p {
                width: 50%;
                &.date {
                  width: 75%;
                }
              }
            }
            .col3 {
              width: 90%;
              display: flex;
              p {
                width: 33%;
              }
            }
            p {
              margin: 0px;
            }
            button {
              @include btn;
              color: rgba($primary-color, 0.4);
              &:hover {
                color: $danger;
              }
            }
          }
        }
        &.file-section {
          label {
            color: white;
          }
          .file {
            display: flex;
            justify-content: space-between;
            align-items: center;
            a {
              display: flex;
              align-items: center;
              text-decoration: none;
              padding: 6px 10px;
              background-color: rgba($primary-color ,0.1);
              width: calc(100% - 30px);
              border-radius: $radius;
              @include bold;
              color: $primary-color;
              @include transition;
              &:hover {
                background-color: rgba($primary-color ,0.2);
              }
            }
            button {
              @include btn;
              color: $primary-color;
              &:hover {
                color: $danger;
              }
            }
          }
        }
      }
    }
  }
}