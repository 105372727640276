
@import '../../styles/variables.scss';

@mixin inline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  label{
    margin-right: 10px;
  }
}


@mixin input {
  @include transition;
    display: block;
    padding: 10px;
    border: none;
    outline: none;
    background-color: transparent;
    border-radius: 0px;
    width: 100%;
    min-width: 250px;
    box-sizing: border-box;
    @include text;
    &:focus{
      border: none;
    }
    &::placeholder {
      color : #a3a3a3;
    }
}

@mixin primary {
  .input {
    @include input;
    border-radius: $radius;
    min-height: 40px;
    padding: 8px 10px;
    min-width: 100px;
    background: #FAFBFB;
    border: 1px solid rgba(79, 60, 200, 0.3);
    color: $black;
    @include label;
    &:focus{
      border: 1px solid rgba(79, 60, 200, 1);
    }
  }
  label {
    color: $black;
  }
}

@mixin login {
  .input {
    @include input;
    border-radius: $radius;
    min-height: 50px;;
    background-color: #FFFFFF;
    box-shadow: 0px 10px 28px 0px rgb(156 167 177 / 30%);
    color: $dark;
  }
  label {
    color:$dark;
  }
};