@import '../../styles/variables.scss';

.container {
  @include box-static;
  padding: 15px 20px;
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  @include scrollbar;
  @media only screen and (min-width: $md-screen) {
    padding: 30px;
  }
  h2 {
    @include title-2;
    font-size: 16px;
    margin: 0px;
    color: $primary-color;
  }
  ul {
    padding: 0px 0px 10px 0px;
    border-bottom: 1px solid rgba(210, 207, 227, 0.55);;
    li {
      list-style: none;
      a {
        text-decoration: none;
        display: block;
        padding: 6px;
        color: rgba($dark, 0.7);
        border-radius: $radius;
        p {
          margin: 0px;
        }
        &:hover {
          background-color: rgba($primary-color , 0.2);
        }
      }
    }
  }
}