@import '../../../../styles/variables.scss';

.button{
  display: inline-flex;
  height: 28px;
  width: 28px;
  justify-content: center;
  align-items: center;
  background-color: $white;
  margin-right: 2px;
  margin-bottom: 8px;
  border-radius: $radius;
  cursor: pointer;
  &.active{
    color : $white;
    background: $primary-color;
  }
  &:hover {
    background: rgba($primary-color, 0.3);
  }
}