@mixin content {
  position: relative;
  width: 90%;
  @media only screen and (min-width: $md-screen) {
    width: 96%;
  }
  @media only screen and (min-width: $lg-screen) {
    width: 90%;
  }
}

@mixin transition {
  transition: all 0.2s ease-in-out;
}



@mixin animation-mixin-x($name, $from, $to) {
  @keyframes #{$name} {
    0% {transform: translateX($from); opacity: 0;}
    100% {transform: translateX($to); opacity: 1;}
  }
}

@mixin animation-scale($name, $from, $to) {
  @keyframes #{$name} {
    0% {transform: scale($from); opacity: 0;}
    100% {transform: scale($to); opacity: 1;}
  }
}

@mixin animation-mixin-y($name, $from, $to) {
  @keyframes #{$name} {
    0% {transform: translateY($from); opacity: 0;}
    100% {transform: translateY($to); opacity: 1;}
  }
}

@mixin animation-fade($name) {
  @keyframes #{$name} {
    0% { opacity: 0;}
    100% { opacity: 1;}
  }
}

@mixin btn {
	cursor: pointer;
  padding: 0px;
	transition: all 0.2s ease-in-out;
	background-color: transparent;
  margin: 0px;
  outline: none;
  border: none;
}

@mixin box {
  @include transition;
  animation: fadeIn 0.3s linear;
  animation-fill-mode: both;
  background: #FFFFFF;
  box-shadow: 0px 10px 28px #EBECED;
  border-radius: 3px;
  margin-bottom: 20px;
}

@mixin box-static {
  @include transition;
  background: #FFFFFF;
  box-shadow: 0px 10px 28px #EBECED;
  border-radius: 3px;
  margin-bottom: 20px;
}


@mixin form-group {
  margin-bottom: 0px;
  label {
    @include label;
    color: rgba($dark, 0.7);
  }
  .value {  
    margin-top: 5px;
  }
}

@mixin input {
  display: block;
  width: 100%;
  padding: 8px;
  border: none;
  outline: none;
  background-color: transparent;
  border-radius: 0px;
  margin: 5px 0px;
  box-sizing: border-box;
  @include text;
  &:focus{
    border: none;
  }
  &::placeholder {
    color : #a3a3a3;
  }
  border-radius: $radius;
  background: #FAFBFB;
  border: 1px solid rgba(79, 60, 200, 0.3);
  color: $black;
  @include label;
  &:focus{
    border: 1px solid rgba(79, 60, 200, 1);
  }
}


@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(60px)
  }
  100% {
    opacity: 1;
    transform: translateY(0px)
  }
}

@keyframes fadeIn2 {
  0% {
    opacity: 0;
    transform: translateY(601px)
  }
  100% {
    opacity: 1;
    transform: translateY(0px)
  }
}


@mixin page-container {
  position: absolute;
  width: 100vw;
  max-width: 100vw;
  height: calc(100vh - 53px);
  top: 53px;
  @media only screen and (min-width: $md-screen) {
    left: 200px;
    width: calc(100vw - 200px);
    height: 100vh;
    top: 0px;
  }
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba($primary-color, 0.5);
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: $primary-color;
  }
}

@mixin scrollbarFilters {
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba($white, 0.5);
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: $white;
  }
}

@mixin animation-scale($name, $from, $to) {
  @keyframes #{$name} {
    0% {transform: scale($from); opacity: 0;}
    100% {transform: scale($to); opacity: 1;}
  }
}

@mixin toggle {
  @include btn;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $primary-color;
  width: 28px;
  height: 28px;
  border-radius: 15px;
  &:hover {
    background-color: rgba($primary-color, 0.2);
  }
  &.open {
    transform: rotate(180deg);
  }
}

@mixin border {
  border-top: 1px solid #EDECF4;
  margin-top: 20px;
  padding-top: 20px;
}

@mixin list {
  margin: 20px 0px 20px 0px;
  .item {
    @include transition;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0px 0px 6px 0px;
    padding: 6px 12px;
    background-color: rgba($primary-color, 0.16);
    border-radius: 8px;
    .bold {
      @include bold;
    }
    p {
      margin: 0px;
      text-align: left;
    }
    button {
      @include btn;
      color: rgba($primary-color, 0.4);
      &:hover {
        color: $danger;
      }
    }
  }
}

@mixin edit-btn {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 40px;
  min-width: 75px;
  padding: 11px;
  z-index: 10;
  box-shadow: 0px 4px 20px rgba($dark, 0.25);
  span {
    display: none;
  }
  svg {
    margin-left: 0px;
  }
  @media only screen and (min-width: $md-screen) {
    position: relative;
    bottom: auto;
    right: auto;
    width: auto;
    padding: 11px 30px 11px;
    margin-left: 20px;
    img {
      display: none;
    }
    svg {
      display: none;
    }
    span {
      display: inline;
      text-transform: uppercase;
    }
  }
}

@mixin back-link {
  display: flex;
  @include title-2;
  text-decoration: none;
  align-items: center;
  text-transform: uppercase;
  font-size: 16px;
  color: $primary-color;
  svg {
    @include transition;
  }
  &:hover {
    svg {
      transform: translateX(-4px);
    }
  }
}

@mixin link {
  text-decoration: none;
  padding: 6px 10px;
  background-color: rgba($primary-color ,0.1);
  width: calc(100% - 30px);
  margin: 0px 0px 6px 0px;
  border-radius: $radius;
  @include bold;
  color: $primary-color;
  @include transition;
  &:hover {
    background-color: rgba($primary-color ,0.2);
  }
}

@mixin file-section {
  .file {
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      padding: 6px 10px;
      background-color: rgba($primary-color ,0.1);
      width: calc(100% - 30px);
      margin: 0px 0px 6px 0px;
      border-radius: $radius;
      @include bold;
      color: $primary-color;
      @include transition;
      &:hover {
        background-color: rgba($primary-color ,0.2);
      }
    }
    button {
      @include btn;
      color: $primary-color;
      &:hover {
        color: $danger;
      }
    }
  }
  .input-file {
    margin-top: 10px;
    label {
      color: $white;
    }
  }
}

@mixin btn-primary {
  @include btn;
  margin-top: 10px;
  background-color: $primary-color;
  @include text;
  color: $white;
  padding: 10px 20px;
  box-shadow: 0px 4px 20px rgba(25, 34, 67, 0.14);
  border-radius: 5px;
}

@mixin check {
  display: flex;
  button {
    @include btn;
    @include label;
    @include bold;
    font-size: 14px;
    background-color: rgba($primary-color , 0.16);
    padding: 5px 12px ;
    color: rgba($dark , 0.6);
    margin: 10px 0px;
    &:first-child {
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
    }
    &:last-child {
      border-bottom-right-radius: 5px;
      border-top-right-radius: 5px;
    }
    &:hover {
      background-color: rgba($primary-color , 0.4);
    }
    &.active {
      color: $white;
      background-color: $primary-color;
    }
  }
}