@import '../../../styles/variables.scss';
@import '../form-mixin.scss';

.container-input-password{
  position: relative;

  label{
    @include label;
  }

  &.inline {
    @include inline;
  }

  &:not(.inline){
    label+.input {
      margin-top: 10px;
    }
  }

  .input {
    @include input;
  }
  &.primary {
    @include primary;
    .container-icon {
      height: 30px;
    }
  }
  &.login {
    @include login;
  }

}

.container-input{
  position: relative;
  width: 100%;

  .container-icon{
    display: block;
    position: absolute;
    top:0;
    right:0;
    height: 36px;
    width: 36px;
    cursor: pointer;
    >svg{
      color: $primary-color;
      position: absolute;
      top:66%;
      left:36%;
      transform: translate(-50%,-50%);
    }
  }

}

.container-input .input {
  display: block;
  margin-top: 10px;
  padding: 10px 36px 10px 10px;
  border: none;
  outline: none;
  background-color: white;
  border-radius: 8px;
  width: 100%;
  min-width: 250px;
  box-sizing: border-box;
  font-size: 14px;

  &::-ms-reveal,
  &::-ms-clear{
    display: none;
  }

  // HIDE KEY ON SAFARI
  &::-webkit-credentials-auto-fill-button {
    margin: 0;
    width: 0;
    background-color: transparent;
  }

  &:focus{
    // box-shadow: 0 0 0 1px $primary-color;
  }
  
  &::placeholder {
    font-size: 14px;
    color : #8c8c8c;
  }
}
