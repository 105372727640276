@import '../../../../styles/variables.scss';

.container {
  width: 100vw;
  height: 100%;
  padding: 20px;
  overflow-y: auto;
  @include scrollbar;
  z-index: 3;
  @media only screen and (min-width: $md-screen) {
    width: calc((100vw - 200px) * 5);
  }
  .col {
    padding: 10px;
    @media only screen and (min-width: $md-screen) {
      padding: 0px 20%;
    }
    .header {
      padding: 20px 0px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      @media only screen and (min-width: $md-screen) {
        padding: 10px 0px;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
      h3 {
        @include title-section;
        font-size: 16px;
        margin: 0px;
      }
      button {
        @include btn;
        background-color: $primary-color;
        @include text;
        color: $white;
        padding: 10px 20px;
        box-shadow: 0px 4px 20px rgba(25, 34, 67, 0.14);
        border-radius: 5px;
        margin-top: 10px;
        @media only screen and (min-width: $md-screen) {
          margin-top: 0px;
        }
      }
    }
  }
}