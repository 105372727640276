@import '../../styles/variables.scss';

.container {
  width: 100%;
  .img {
    width: calc(100% - 10px);
    min-height: 120px;
    margin-top: 5px;
    border-radius: $radius;
    position: relative;
    &.icon {
      border-radius: $radius;
      position: relative;
      width: 80px;
      height: 80px;
      min-height: 80px;
      &.large {
        width: calc(100% - 40px);
        height: auto;
        min-height: auto;
      }
    }
    button {
      @include btn;
      cursor: pointer;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 14px;
      right: -25px;
      background-color: $primary-color;
      width: 50px;
      height: 50px;
      border-radius: $radius;
      color: $white;
      box-shadow: 0px 4px 20px rgba(25, 34, 67, 0.245028);
    }
    img , video {
      width: 100%;
      min-height: 100%;
      border-radius: $radius;
      object-fit: cover;
    }
  }
  .input {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 14px;
    right: -25px;
    background-color: $primary-color;
    width: 50px;
    height: 50px;
    border-radius: $radius;
    box-shadow: 0px 4px 20px rgba(25, 34, 67, 0.245028);
    label {
      position: relative;
      color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    input {
      position: absolute;
      opacity: 0;
      width: 50px;
      height: 50px;
      padding: 0px;
    }
  }
}