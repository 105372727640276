@import '../../../styles/variables.scss';

.container {
  @include page-container;
  display: flex;
  flex-direction: column;
  header {
    padding: 0px 20px;
    @media only screen and (min-width: $md-screen) {
      display: flex;
      justify-content: space-between;
      padding: 20px 40px;
    }
    .title {
      h2 {
        @include title-2;
        margin-bottom: 10px;
        text-transform: uppercase;
      }
      .link {
        @include back-link;
      }
    }
    .btn-container {
      width: 100%;
      @media only screen and (min-width: $md-screen) {
        width: auto;
        display: flex;
        justify-content: flex-end;
      }
      button {
        width: 100%;
        @media only screen and (min-width: $md-screen) {
          width: 220px;
          min-height: 57px;
        }
      }
      .edit {
        @include edit-btn;
      }
    }
  }
  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-top: 10px;
    width: 100%;
    height: calc(100vh - 130px);
    overflow-y: auto;
    @include scrollbar;
    z-index: 3;
    @media only screen and (min-width: $md-screen) {
      padding: 5px 40px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }
    .col {
      width: 100%;
      @media only screen and (min-width: $md-screen) {
        width: calc(50% - 10px);
      }
      section {
        @include box-static;
        padding: 20px;
        h3 {
          @include title-section;
          margin: 0px;
        }
        .form-group {
          margin-top: 10px;
          @include form-group;
        }
        .toggle {
          @include toggle;
        }
        .title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0px 0px;
          h2 {
            transform: translateY(-4px);
          }
          .icon {
            svg {
              transform: translateY(5px);
              margin-right: 5px;
            }
          }
        }
        .section-content {
          @include transition;
          overflow: visible;
          &.close {
            opacity: 0;
            pointer-events: none;
            overflow: hidden;
          }
          .list {
            @include list;
          }
        }
        .team {
          margin: 10px 0px;
          border-top: 1px solid #EDECF4;
          padding-top: 10px;
          .label {
            width: 140px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            h4 {
              margin: 0px 6px 0px 0px;
            }
          }
        }
      }
    }
  }
}