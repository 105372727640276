@import '../../styles/variables.scss';

.list {
  margin-top: 10px;
  .item {
    @include transition;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0px 0px 6px 0px;
    padding: 6px 12px;
    background-color: rgba($primary-color, 0.16);
    border-radius: 8px;
    p {
      margin: 0px;
      @include bold;
    }
    button {
      @include btn;
      color: rgba($primary-color, 0.6);
      &:hover {
        color: $danger;
      }
    }
  }
  .add-item {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 15px;
    .input-container {
      width: 100%;
      label {
        @include label;
        color: rgba($dark, 0.7);
      }
      .input {
        @include transition;
        display: block;
        padding: 8px;
        border: none;
        outline: none;
        background-color: transparent;
        border-radius: 0px;
        width: 100%;
        min-width: 250px;
        margin-top: 5px;
        box-sizing: border-box;
        @include text;
        &:focus{
          border: none;
        }
        &::placeholder {
          color : #a3a3a3;
        }
        border-radius: $radius;
        min-height: 40px;
        min-width: 100px;
        background: #FAFBFB;
        border: 1px solid rgba(79, 60, 200, 0.3);
        color: $black;
        @include label;
        &:focus{
          border: 1px solid rgba(79, 60, 200, 1);
        }
      }
    }
    button {
      @include btn;
      margin-top: 10px;
      background-color: $primary-color;
      @include text;
      color: $white;
      padding: 10px 20px;
      box-shadow: 0px 4px 20px rgba(25, 34, 67, 0.14);
      border-radius: 5px;
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }  
    }
  }
}