@import '../../../styles/variables.scss';

.container {
  margin-bottom: 0px;
  .title {
    display: flex;
    width: 100%;
    padding: 10px 15px;
    justify-content: space-between;
    align-items: center;
    h2 {
      @include bold;
      margin: 0px;
      font-size: 16px;
      color: $primary-color;
    }
    .toggle {
      @include btn;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $primary-color;
      width: 28px;
      height: 28px;
      border-radius: 15px;
      &:hover {
        background-color: rgba($primary-color, 0.2);
      }
      &.open {
        transform: rotate(180deg);
      }
    }
  }
  .section-content {
    @include transition;
    padding-bottom: 0px;
    .input-list {
      display: flex;
      flex-direction: column;
      padding: 15px;
      label {
        @include label;
        color: rgba($dark, 0.7);
      }
      .block {
        margin-bottom: 0px;
        .value {  
          margin-top: 5px;
        }
      }
      .tags-list {
        margin-top: 8px;
        button {
          @include btn;
          @include label;
          @include bold;
          font-size: 14px;
          background-color: rgba($primary-color , 0.16);
          padding: 5px 12px ;
          border-radius: 5px;
          color: rgba($dark , 0.6);
          margin: 0px  10px 10px 0px;
          &:hover {
            background-color: rgba($primary-color , 0.4);
          }
          &.selected {
            color: $white;
            background-color: $primary-color;
          }
        }
      }
    }
  }
}