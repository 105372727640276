@import '../../styles/variables.scss';

.container {
  color: $dark;
  &.not-edit {
    pointer-events: none;
    .true {
      color: $white;
      background-color: $primary-color;
    }
  }
  &.boolean {
    .list {
      button {
        width: calc(50% - 20px);
        max-width: 120px;
      }
    }
  }
  &.is-filter {
    .list {
      button {
        background: rgba(255, 255, 255, 0.15);
        color: $white;
        &:hover {
          background-color: rgba($dark , 0.4);
        }
        &.selected {
          color: rgba($dark , 0.6);
          background-color: $white;
        }
      }
    }
  }
  .list {
    margin-top: 8px;
    button {
      @include btn;
      @include label;
      @include bold;
      font-size: 14px;
      background-color: rgba($primary-color , 0.16);
      padding: 5px 12px ;
      border-radius: 5px;
      color: rgba($dark , 0.6);
      margin: 0px  10px 10px 0px;
      &:hover {
        background-color: rgba($primary-color , 0.4);
      }
      &.selected {
        color: $white;
        background-color: $primary-color;
      }
    }
  }
}