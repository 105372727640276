@import '../../styles/variables.scss';

.container {
  @include page-container;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  padding-top: 60px;
  @media only screen and (min-width: $md-screen) {
    left: 0px;
    width: 100%;
    height: 100vh;
    top: 0px;
  }
  .back {
    position: fixed;
    top: 20px;
    left: 20px;
    display: flex;
    align-items: center;
    color: $primary-color;
    cursor: pointer;
  }
  .content {
    position: relative;
    width: 90%;
    @media only screen and (min-width: $md-screen) {
      width: 80%;
    }
    @media only screen and (min-width: $lg-screen) {
      width: 70%;
    }
    h1 {
      font-size: 40px;
      margin-bottom: 0px;
      &:after {
        content:'';
        display: inline-block;
        border-bottom:  11px solid rgba($primary-color , 0.5);
        width: 35%;
        position: absolute;
        left: -12px;
        transform: translateY(34px);
      }
    }
    .bloc {
      border: 1px solid $dark;
      padding: 20px;
      h4 {
        text-align: center;
        margin-top: 0px;
      }
      p {
        font-size: 14px;
      }
    }
    .intro {
      text-transform: uppercase;
      font-family: "AnyBody-SemiBold";
    }
    h3 {
      font-weight: bold;
      margin-top:40px;
    }
    li {
      margin: 10px 0px;
    }
    table {
      margin: 40px 0px;
      border-collapse: collapse;
      th {
        background-color: $primary-color;
        padding: 15px 20px;
        text-align: left;
        color: $white;
      }
      td {
        background-color: rgba($primary-color , 0.2);
        padding: 10px 20px;
        text-align: left;
        border-bottom: 1px solid $white;
      }
    }
  }
  a {
    color: $primary-color;
  }
  .end {
    width: 100%;
    height: 50px;
  }
}