@import '../../../../styles/variables.scss';

.container {
  width: 100vw;
  height: 100%;
  padding: 20px;
  overflow-y: auto;
  @include scrollbar;
  z-index: 3;
  @media only screen and (min-width: $md-screen) {
    width: calc((100vw - 200px) * 5);
  }
  .row {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .col {
      width: 100%;
      @media only screen and (min-width: $md-screen) {
        width: calc(70% - 5px);
        padding: 0px 10px;
      }
      .box {
        @include box-static;
        padding: 20px;
      }
      section {
        &:not(:last-child) {
          border-bottom: 1px solid #EDECF4;
          padding-bottom: 20px;
          margin-bottom: 20px;
          padding-top: 0px;
        }
        h3 {
          @include title-section;
          margin: 0px 0px 10px 0px;
        }
        label {
          @include label;
          color: rgba($dark, 0.7);
        }
        .cover {
          margin-top: 20px;
        }
        .drug-list {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 10px;
          p {
            @include link;
            width: auto;
            margin: 5px 5px 0px 0px;
          }
        }
        .input-block {
          margin-bottom: 10px;
          .input {  
            margin-top: 5px;
          }
        }
        .dates {
          margin-bottom: 30px;
          .date {
            display: flex;
            align-items: center;
            margin-bottom: 8px;
            svg {
              display: block;
              color: $primary-color;
            }
            p {
              margin: 0px 5px;
              color: rgba($dark, 0.7);
              &.label {
                width: 120px;
              }
            }
            span {
              @include bold;
              display: block;
              margin: 0px 30px 0px 0px;
            }
          }
        }
        .link {
          @include link;
        }
        .list {
          margin-top: 10px;
          .item {
            @include transition;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin: 0px 0px 6px 0px;
            padding: 6px 12px;
            background-color: rgba($primary-color, 0.16);
            border-radius: 8px;
            button {
              @include btn;
              color: rgba($primary-color, 0.4);
              &:hover {
                color: $danger;
              }
            }
          }
        }
        .file-section {
          margin-top: 10px;
          .file {
            display: flex;
            justify-content: space-between;
            align-items: center;
            a {
              display: flex;
              align-items: center;
              @include link;
              width: 100%;
            }
            button {
              @include btn;
              color: $primary-color;
              &:hover {
                color: $danger;
              }
            }
          }
          .input-file {
            margin-top: 10px;
            label {
              color: $white;
            }
          }
        }
      }
    }
  }
}