@import '../../../styles/variables.scss';

.container {
  @include page-container;
  display: flex;
  flex-direction: column;
  header {
    padding: 0px 20px;
    @media only screen and (min-width: $md-screen) {
      display: flex;
      justify-content: space-between;
      padding: 20px 40px;
    }
    .title {
      h2 {
        @include title-2;
        margin-bottom: 10px;
        text-transform: uppercase;
      }
      .link {
        @include back-link;
      }
    }
    .btn-container {
      width: 100%;
      @media only screen and (min-width: $md-screen) {
        width: auto;
        display: flex;
        justify-content: flex-end;
      }
      button {
        width: 100%;
        @media only screen and (min-width: $md-screen) {
          width: 220px;
          min-height: 57px;
        }
      }
      .edit {
        @include edit-btn;
      }
    }
  }
  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-top: 10px;
    width: 100%;
    height: calc(100vh - 130px);
    overflow-y: auto;
    @include scrollbar;
    z-index: 3;
    @media only screen and (min-width: $md-screen) {
      padding: 5px 40px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }
    .col {
      width: 100%;
      @include box-static;
      padding: 20px;
      @media only screen and (min-width: $md-screen) {
        padding: 30px;
        width: calc(60% - 10px);
        &.apercu {
          width: calc(40% - 10px);
        }
      }
      section {
        &:not(:last-child) {
          border-bottom: 1px solid #EDECF4;
          padding-bottom: 20px;
          margin-bottom: 20px;
          padding-top: 0px;
        }
        h3 {
          @include title-section;
          margin: 0px;
        }
        label {
          @include label;
          color: rgba($dark, 0.7);
        }
        .input-list {
          display: flex;
          flex-wrap: wrap;
          margin-top: 10px;
          .block {
            margin-bottom: 10px;
            width: 100%;
            .value {
              margin-top: 5px;
              min-height: 20px;
              @include label;
              p {
                margin: 0px;
                &.bold {
                  font-weight: 600;
                }
              }
              a {
                color: $primary-color;
              }
              span {
                opacity: 0.3;
              }
            }
          }
        }
        .list {
          flex-wrap: wrap;
          display: flex;
          margin: 10px 0px;
          .item {
            @include transition;
            @include bold;
            width: auto;
            font-size: 14px;
            background-color: rgba($primary-color , 0.16);
            padding: 5px 12px ;
            border-radius: 5px;
            text-decoration: none;
            margin: 0px  10px 10px 0px;
            &:hover {
              background-color: rgba($primary-color , 0.3);
            }
          }
          .link {
            @include transition;
            @include bold;
            width: auto;
            display: flex;
            align-items: center;
            font-size: 14px;
            padding: 3px 0px ;
            border-radius: 5px;
            text-decoration: underline;
            color: $primary-color;
            margin: 0px  10px 10px 0px;
            svg {
              margin-top: 2px;
              margin-left: 3px;
            }
          }
          &.links {
            flex-wrap: nowrap;
            flex-direction: column;
            background-color: transparent;
          }
        }
      }
    }
  }
}