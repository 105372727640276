@import '../../styles/variables.scss';

.container {
  @include page-container;
  display: flex;
  flex-direction: column;
  top: 0px;
  height: 100vh;
  .left-corner {
    display: none;
    @media only screen and (min-width: $md-screen) {
      display: block;
      height: 100px;
      width: 300px;
      position: absolute;
      left: -200px;
      top: 0px;
      background-color: $primary-color;
    }
  }
  header {
    width: 100%;
    height: 200px;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    background-color: $primary-color;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (min-width: $md-screen) {
      height: 300px;
    }
    .logo {
      position: absolute;
      top: 0px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      h2 {  
        @include title-2;
        color: $white;
      }
      img {
        max-height: 15px;
        margin-right: 8px;
      }
      @media only screen and (min-width: $md-screen) {
        top: 30px;
        h2 {
          font-size: 42px;
        }
        img {
          width: 36px;
          max-height: 44px;
          margin-right: 8px;
        }
      }
    }
    .input {
      width: calc(100% - 40px);
      position: relative;
      @media only screen and (min-width: $md-screen) {
        width: calc(80% - 40px);
        max-width: 600px;
      }
      input {
        width: 100%;
        height: 45px;
        padding: 10px;
        outline: none;
        border-radius: $radius;
        border: none;
        @include text;
        &:focus{
          border: none;
        }
        &::placeholder {
          color : #a3a3a3;
        }
      }
      .icon {
        position: absolute;
        @include btn;
        right: 8px;
        top: 8px;
        width: 36px;
        height: 36px;
        color: rgba($dark, 0.5);
        border-radius: 20px;
      }
    }
  }
  main {
    transform: translateY(-40px);
    padding: 0px 20px;
    max-height: 70vh;
    @media only screen and (min-width: $md-screen) {
      max-height: 60vh;
      padding: 0px 8%;
    }
    .dashboard {
      animation: fadeIn 0.3s linear;
      animation-fill-mode: both;
    }
    .search {
      animation: fadeIn2 0.32s linear;
      animation-fill-mode: both;
      height: calc(100%);
    }
    .box {
      @include box-static;
      padding: 15px 20px;
      width: 100%;
      display: flex;
      .col {
        width: 100%;
        @media only screen and (min-width: $md-screen) {
          width: 50%;
        }
      }
      .img {
        display: none;
        @media only screen and (min-width: $md-screen) {
          display: flex;
          justify-content: center;
          width: 50%;
          img {
            max-width: 100%;
            object-fit: cover;
          }
        }
      }
      h2 {
        @include title-2;
        text-transform: uppercase;
        font-size: 16px;
        margin: 0px;
        color: $primary-color;
      }
      ul {
        padding: 0px;
        li {
          list-style: none;
          display: flex;
          align-items: center;
          background: rgba(79, 60, 200, 0.06);
          border-radius: $radius;
          margin-bottom: 10px;
          p {
            color: rgba($dark, 0.7);
            margin: 0px 10px;
          } 
        }
      }
    }
    .count-list {
      display: flex;
      justify-content: space-between;
      .count {
        width: calc(33.33% - 10px);
        padding: 10px;
        @include box-static;
        h3 {
          @include title-2;
          text-transform: uppercase;
          font-size: 36px;
          line-height: 36px;
          color: $primary-color;
          margin: 0px 0px 10px 0px;
        }
        p {
          margin: 0px;
          color: rgba($dark, 0.7);
        }
      }
    } 
  }
}