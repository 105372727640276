@import '../../../styles/variables.scss';

.overlay-modal{
  position: fixed;
  z-index: 999;
  top:0;
  height: 100%;
  width: 100%;
  background-color: rgba(200,200,255,.4);
}

.modal{
  position: fixed;
  z-index: 1000;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  border-radius: 8px;
  background-color: white;
  .close {
    position: absolute;
    @include btn;
    top: 10px;
    right: 10px;
    font-size: 20px;
    width: 25px;
    height: 25px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: rgba($dark , 0.2);
    }
  }
}
