@import '../../../../styles/variables.scss';

.container {
  margin: 0px auto 20px auto;
  width: calc(100% - 40px);
  @include box-static;
  padding: 15px 20px;
  @media only screen and (min-width: $md-screen) {
    width: 80%;
  }
  h1 {
    margin: 0px;
  }
  h2 {
    @include title-section;
    margin: 0px;
  }
  .border {
    @include border;
  }
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .toggle {
      @include toggle;
    }
  }
  .content {
    @include transition;
    overflow: hidden;
    label {
      @include label;
      color: rgba($dark, 0.7);
    }
    .bold {
      @include bold;
    }
    .row {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      @media only screen and (min-width: $md-screen) {
        flex-direction: row;
        margin-top: 0px;
      }
      .col {
        flex: 1;
        &.icon {
          display: flex;
          align-items: center;
          svg {
            color: $primary-color;
            margin-right: 10px;
            transform: translateY(-2px);
          }
        }
      }
    }
    .list {
      margin-bottom: 10px;
      margin-top: 10px;
      p {
        @include bold;
        margin: 8px 0px;
      }
    }
  }
}