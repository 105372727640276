@import '../../../styles/variables.scss';

@mixin tag {
  margin-top: 10px;
  background-color: $primary-color;
  border-radius: 5px;
  padding: 5px 10px;
  text-align: center;
  color: $white;
}  

.container {
  @include page-container;
  display: flex;
  flex-direction: column;
  header {
    padding: 0px 20px;
    @media only screen and (min-width: $md-screen) {
      display: flex;
      justify-content: space-between;
      padding: 20px 40px;
    }
    .title {
      h2 {
        @include title-2;
        margin-bottom: 10px;
        text-transform: uppercase;
        display: none;
        @media only screen and (min-width: $md-screen) {
          display: block;
        }
      }
      .link {
        @include back-link;
      }
    }
    .btn-container {
      width: 100%;
      @media only screen and (min-width: $md-screen) {
        width: auto;
        display: flex;
        justify-content: flex-end;
      }
      button {
        width: 100%;
        @media only screen and (min-width: $md-screen) {
          width: 220px;
          min-height: 57px;
        }
      }
      a {
        text-decoration: none;
      }
      .edit {
        @include edit-btn;
      }
    }
  }
  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: calc(100vh - 75px);
    padding: 20px;
    overflow: auto;
    @include scrollbar;
    @media only screen and (min-width: $md-screen) {
      padding: 5px 40px;
      height: calc(100vh - 107px);
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }
    .border {
      @include border;
    }
    label {
      @include label;
      color: rgba($dark, 0.7);
      &.bold {
        @include bold;
        color: rgba($dark, 0.7);
      }
    }
    .list {
      @include list;
      .item {
        background-color: rgba($primary-color, 0.08);
        p {
          width: 30%;
        }
      }
    }
    .row {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      &.date-row {
        margin: 10px 0px;
        align-items: flex-start;
      }
      .date {
        width: 100%;
        display: flex;
        align-items: center;
        font-weight: bold;
        svg {
          color: $primary-color;
          transform: translateY(-1px);
          margin-right: 10px;
        }          
      }
      .url {
        width: 100%;
        margin: 10px 0px;
        display: flex;
        align-items: center;
        a {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: $primary-color;
          p {
            margin: 0px;
            max-width: 100%;
            overflow: hidden;
          }
          img {
            max-width: 25px;
          }
        }
         
      }
      @media only screen and (min-width: $sm-screen) {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        &.date-row {
          margin: 10px 0px;
          align-items: center;
        }
        .col-2 {
          width: calc(50% - 10px);
        }
        .col-3 {
          width: calc(33.33% - 10px);
        }
        .date {
          width: calc(30% - 10px);         
        }
        .url {
          width: calc(70% - 10px);
          margin: 0px;        
        }
      }
    }
    .cips {
      margin-top: 15px;
      .cip {
        padding: 15px;
        width: 100%;
        margin-bottom: 10px;
        position: relative;
        background-color: #FAFBFB;
        border: 1px solid rgba($primary-color, 0.3);
        cursor: pointer;
        &:hover {
          background-color: #eef1f1;
        }
        .row {
          width: 100%;
          display: flex;
          flex-direction: column;
          margin-top: 0px;
          &:first-child {
            margin-bottom: 10px;
          }
          @media only screen and (min-width: $sm-screen) {
            flex-direction: row;
            justify-content: space-between;
            .col {
              width: calc(33.33% - 10px);
            }
            .url {
              width: calc(67.66% - 10px);
            }
          }
        }
      } 
    }
    aside {
      display: flex;
      flex-direction: column;
      width: 100%;
      @media only screen and (min-width: $md-screen) {
        width: calc(35% - 20px);
      }
      .list {
        @include list;
        margin: 5px 0px;
        &.variations {
          margin: 10px 0px;
          .link {
            @include bold;
            color: #0EAF9C;
            display: flex;
            align-items: center;
            margin: 5px 0px;
            padding: 5px 0px;
            width: 100%;
            background-color: transparent;
            svg {
              margin-left: 5px;
            }
            &:hover {
              color: #0b8375;
            }
          }
        }
        .item {
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
        }
      }
      .site {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        .tags-list {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 10px;
          .tag {
            @include tag;
            margin: 6px 6px 0px 0px;
          }
        }
      }
    }
    main {
      width: 100%;
      @media only screen and (min-width: $md-screen) {
        width: 65%;
      }
      .infos {
        @include box-static;
        padding: 20px;
        h2 {
          @include title-section;
        }
        .col-2 {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-top: 20px;
          @media only screen and (min-width: $md-screen) {
            &:first-child {
              border-right: 1px solid #E1E1E1;
            }
          }
          .img {
            width: 140px;
            height: 70px;
            background-color: rgba($dark, 0.05);
            margin-right: 20px;
            border-radius: $radius;
            border: 1px solid #E1E1E1;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 100%;
              max-height: 100%;
              object-fit: contain;
            }
          }
          p {
            @include bold;
            margin: 0px;
            padding-top: 4px;
          }

        }
        .areas {
          margin-top: 20px;
          display: flex;
          flex-direction: column;
          .tags-list {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 0px;
            .tag {
              @include tag;
              margin: 6px 6px 0px 0px;
            }
          }
        }
      }
      .process {
        margin-top: 20px;
        .col-2 {
          &:first-child {
            margin-bottom: 20px;
          }
          @media only screen and (min-width: $md-screen) {
            &:first-child {
              margin-bottom: 0px;
              border-right: 1px solid #E1E1E1;
            }
          }
        }
        ul {
          padding-left: 0px;
          list-style: none;
          margin: 10px 0px 0px 0px;
          li {
            position: relative;
            padding: 10px 0px 10px 20px;
            color: rgba($dark, 0.7);
            &::before {
              content: "";
              position: absolute;
              left: 2px;
              top: 13px;
              display: block;
              width: 10px;
              height: 10px;
              border-radius: 7px;
              background-color: $primary-color;
            }
            &:not(:last-child) {
              &::after {
                content: "";
                position: absolute;
                left: 6px;
                top: 13px;
                height: 100%;
                display: block;
                border-left: 1px dashed $primary-color;
              }
            }
          }
        }
      }
      .reconstitution {
        .media {
          width: 100%;
          video , img {
            width: 100%;
          }
        }
      }
      .composition {
        .list {
          .labels {
            padding: 0px 6px;
            display: flex;
            justify-content: space-between;
            p {
              @include label;
              width: 25%;
            }
          }
          .compo-item {
            @include bold;
            width: 25%;
          }
        }   
        .row {
          margin-top: 10px;
          p {
            margin: 0px;
            @include bold;
            margin-bottom: 10px;
            @media only screen and (min-width: $md-screen) {
              margin-bottom: 0px;
            }
          }
        }
        .form {
          width: 120px;
          background-color: $primary-color;
          border-radius: 5px;
          padding: 5px 10px;
          text-align: center;
          color: $white;
          @media only screen and (min-width: $md-screen) {
            width: 30%;
          }
        }
        .form-description {
          width: 100%;
          @media only screen and (min-width: $md-screen) {
            width: calc(70% - 20px);
          }
        }
      }
      .excipients {
        .list {
          p {
            width: 100%;
            padding: 10px;
          }
        }
      }
      .pharmaco {
        .col-2 {
          margin: 10px 0px;
        }
        p {
          @include bold;
          margin: 0px;
          padding-top: 2px;
          width: 33%;
          &.text {
            margin: 10px 0px;
            width: 100%;
          }
        }
      }
      .data {
        margin-top: 10px;
        .col-2 {
          margin: 10px 0px;
        }
        p {
          @include bold;
          margin: 0px;
          padding-top: 2px;
        }
      }
      .study {
        margin: 20px 0px;
        &:not(:last-child) {
          border-bottom: 1px solid #E1E1E1;
        }
        .list {
          margin: 5px 0px 20px 0px;
          .link {
            @include bold;
            color: #0EAF9C;
            display: flex;
            align-items: center;
            margin: 5px 0px;
            padding: 5px 0px;
            width: 100%;
            background-color: transparent;
            &:hover {
              color: #0b8375;
            }
          }
        }
      }
      .regulations {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        .list {
          width: 100%;
          margin: 5px 0px;
        }
        p {
          @include bold;
          margin: 0px;
          padding-top: 2px;
        }
        .item {
          display: flex;
          width: 100%;
        }
        .tag {
          @include tag;
        }
      }
      .pgp {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        .tag {
          @include tag;
        }
      }
    } 

  }
}
