.list-tags{

  label{
    font-size: 14px;
  }

  &.inline{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    label{
      margin-right: 10px;
    }
  }

  &:not(.inline){
    label+.ul{
      margin-top: 10px;
    }
  }

  ul{
    padding: 0;
    margin: 0;
    li{
      display: inline-block;
      color : white;
      font-size: 12px;
      background-color: darken(#D2D2D2, 10%);
      padding: 5px 8px;
      box-sizing: border-box;
      border-radius: 5px;
      cursor: pointer;

      &:not(:last-child){
        margin-right: 5px;
      }

      &.selected{
        background-color: #00122C;
      }

    }
  }
}
