@import '../../../../../styles/variables.scss';

.container {
  padding: 30px 50px;
  @media only screen and (min-width: 1270px) {
    width: 60vw;
  }
  label {
    @include label;
    color: rgba($dark, 0.7);
  }
  .bold {
    @include bold;
  }
  h3 {
    @include bold;
    font-size: 20px;
    margin: 0px 0px 30px;
    color: $primary-color;
  }
  .form-group {
    @include form-group;
    margin-top: 10px;
    margin-bottom: 20px;
    .bold {
      @include bold;
      color: rgba($dark, 0.7);
    }
    @media only screen and (min-width: $sm-screen) {
      margin-bottom: 10px;
    }
  }
  .row {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    @media only screen and (min-width: $sm-screen) {
      flex-direction: row;
      justify-content: space-between;
      .col {
        width: calc(33.33% - 10px);
        &.url {
          width: calc(66.66% - 10px);;
        }
      }
    }
  }
  .btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    .edit {
      margin: 0px;
      font-family: "Anybody-SemiBold";
      color: $white;
      background-color: $primary-color;
    }
    .delete {
      @include btn;
      @include bold;
      padding: 10px 20px;
      background-color: transparent;
      color: $danger;
      border-radius: $radius;
      &:hover {
        background-color: $danger;
        color: $white;
      }
    }
  }
}