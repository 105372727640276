@import '../../styles/variables.scss';

.container {
  @include box-static;
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: $md-screen) {
    flex-direction: row;
  }
  .name {
    width: 100%;
    margin-bottom: 20px;
    @media only screen and (min-width: $md-screen) {
      width: 20%;
      border-right: 1px solid #EDECF4;
      margin-right: 20px;
    }
    h3 {
      @include title-section;
      margin: 0px;
    }
    button {
      @include btn;
      @include text;
      margin-top: 10px;
      color: $danger;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .form {
    width: 100%;
    @media only screen and (min-width: $md-screen) {
      width: 70%;
    }
    .form-group {
      margin-top: 10px;
    }
    label {
      @include label;
      color: rgba($dark, 0.7);
    }
    .date {
      width: 100%;
      margin-bottom: 10px;
      @media only screen and (min-width: $md-screen) {
        width: calc(50% - 10px);
      }
      .input {  
        margin-top: 5px;
      }
    }
    .count {
      @include bold;
      color: $primary-color;
      margin: 20px 0px 0px 0px;
      @media only screen and (min-width: $md-screen) {
        width: 70%;
      }
    }
    .text-area {
      @include transition;
      @include input;
      min-height: 200px;
    }
    .title {
      @include transition;
      @include input;
    }
    .btn-container {
      display: flex;
      justify-content: flex-end;
      .btn {
        @include btn;
        margin-top: 10px;
        background-color: $primary-color;
        @include text;
        color: $white;
        padding: 10px 20px;
        box-shadow: 0px 4px 20px rgba(25, 34, 67, 0.14);
        border-radius: 5px;
      }
    }
    .list {
      margin: 20px 0px;
      .item {
        @include transition;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin: 0px 0px 6px 0px;
        padding: 6px 12px;
        background-color: rgba($primary-color, 0.16);
        border-radius: 8px;
        .col2 {
          width: 90%;
          display: flex;
          p {
            width: 50%;
          }
        }
        p {
          margin: 0px;
        }
        button {
          @include btn;
          color: rgba($primary-color, 0.4);
          &:hover {
            color: $danger;
          }
        }
      }
    }
  }
  .toggle {
    @include toggle;
    @include btn;
    position: absolute;
    right: 20px;
    &.isOpen {
      transform: rotate(180deg);
    }
  }
}