@import '../../styles/variables.scss';

.list {
  .add-item {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .select-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .select {
        width: calc(100% - 10px);
        margin: 10px 20px 0px 0px; 
      }
      button {
        @include btn;
        margin-top: 10px;
        background-color: $primary-color;
        @include text;
        color: $white;
        padding: 10px 20px;
        box-shadow: 0px 4px 20px rgba(25, 34, 67, 0.14);
        border-radius: 5px;
      }
    }
    .sentence-container {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    .input-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      @media only screen and (min-width: $sm-screen) {
        flex-direction: row;
        justify-content: space-between;
      }
      .input {
        width: 100%;
        &.col-2 {
          width: 100%;
          @media only screen and (min-width: $sm-screen) {
            width: calc(50% - 10px);
          }
        }
        &.col-3 {
          width: 100%;
          @media only screen and (min-width: $sm-screen) {
            width: calc(33% - 10px);
          }
        }
        &.col-4 {
          width: 100%;
          @media only screen and (min-width: $sm-screen) {
            width: calc(25% - 10px);
          }
        }
        &.col-5 {
          width: 100%;
          @media only screen and (min-width: $sm-screen) {
            width: calc(20% - 10px);
          }
        }
      }
      input {
        @include transition;
        display: block;
        width: 100%;
        padding: 8px;
        border: none;
        outline: none;
        background-color: transparent;
        border-radius: 0px;
        margin: 5px 0px;
        box-sizing: border-box;
        @include text;
        &:focus{
          border: none;
        }
        &::placeholder {
          color : #a3a3a3;
        }
        border-radius: $radius;
        min-height: 40px;
        background: #FAFBFB;
        border: 1px solid rgba(79, 60, 200, 0.3);
        color: $black;
        @include label;
        &:focus{
          border: 1px solid rgba(79, 60, 200, 1);
        }
      }
    }
    .add-btn {
      @include btn-primary;
    }
  }
}