@import '../../../styles/variables.scss';

.container {
  position: absolute;
  top: 0px;
  width: 100vw;
  max-width: 100vw;
  height: calc(100vh - 53px);
  transform: translateX(calc(100% + 20px));
  opacity: 0;
  background-color: $primary-color;
  border-radius: 0px;
  position: absolute;
  z-index: 3000;
  @include transition;
  @media only screen and (min-width: $md-screen) {
    border-radius: $radius;
    height: calc(100vh - 40px);
    width: 320px;
    right: 20px;
    top: 20px;
  }
  &.is-active {
    transform: translateX(0);
    opacity: 1;
  }
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px 0px 20px;
    h2 {
      margin: 0px;
      text-transform: uppercase;
      color: $white;
      @include title-2;
    }
    button {
      @include btn;
      color: $white;
    }
  }
  .filters {
    padding: 10px 20px;
    max-height: calc(100vh - 100px);
    overflow-y: auto;
    label {
      color: $white;
    }
    .search {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      input {
        margin-top: 6px;
        border: none;
        @include text;
        padding: 5px;
        height: 32px;
        outline: none;
        border-radius: $radius;
        width: calc(100% - 42px);
        &:focus {
          outline: none;
        }
      }
      button {
        @include btn;
        margin-top: 6px;
        color: $white;
        width: 34px;
        height: 34px;
        border-radius: $radius;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba($primary-dark , 0.6);
        &.is-search {
          background-color: $primary-dark ;
        }
      }
    }
    .tags {
      margin-top: 20px;
    }
  }
}