@import '../../../styles/variables.scss';

.container {
  @include page-container;
  display: flex;
  flex-direction: column;
  header {
    padding: 0px 20px;
    @media only screen and (min-width: $md-screen) {
      display: flex;
      justify-content: space-between;
      padding: 20px 40px;
    }
    .title {
      h2 {
        @include title-2;
        margin-bottom: 10px;
        text-transform: uppercase;
      }
      .link {
        @include back-link;
      }
    }
    .btn-container {
      width: 100%;
      @media only screen and (min-width: $md-screen) {
        width: auto;
        display: flex;
        justify-content: flex-end;
      }
      button {
        width: 100%;
        @media only screen and (min-width: $md-screen) {
          width: 220px;
          min-height: 57px;
        }
      }
      .edit {
        @include edit-btn;
      }
    }
  }
  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-top: 10px;
    width: 100%;
    height: calc(100vh - 130px);
    overflow-y: auto;
    @include scrollbar;
    z-index: 3;
    @media only screen and (min-width: $md-screen) {
      padding: 5px 40px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }
    .col {
      width: 100%;
      @include box-static;
      padding: 20px;
      @media only screen and (min-width: $md-screen) {
        padding: 30px;
        width: calc(60% - 10px);
        &.apercu {
          width: calc(40% - 10px);
        }
      }
      section {
        &:not(:last-child) {
          border-bottom: 1px solid #EDECF4;
          padding-bottom: 20px;
          margin-bottom: 20px;
          padding-top: 0px;
        }
        h3 {
          @include title-section;
          margin: 0px;
        }
        label {
          @include label;
          color: rgba($dark, 0.7);
        }
        .input-list {
          display: flex;
          flex-wrap: wrap;
          margin-top: 10px;
          .block {
            margin-bottom: 10px;
            width: 100%;
            .value {
              margin-top: 5px;
              min-height: 20px;
              @include label;
              &.check {
                margin-bottom: 20px;
              }
              &.code {
                width: 100%;
                @media only screen and (min-width: $md-screen) {
                  width: 50%;
                }
              }
              p {
                &.bold {
                  font-weight: 600;
                }
              }
              a {
                word-break: break-all;
                color: $primary-color;
              }
              .content-none {
                opacity: 0.3;
              }
            }
            .reco {
              font-size: 12px;
              color: rgba($dark, 0.7);
            }
            .icon {
              margin-top: 5px;
              border-radius: $radius;
              position: relative;
              width: 80px;
              height: 80px;
              .color {
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                top: 14px;
                right: -25px;
                background-color: $primary-color;
                width: 50px;
                height: 50px;
                border-radius: $radius;
                box-shadow: 0px 4px 20px rgba(25, 34, 67, 0.245028);
                label {
                  position: relative;
                  color: $white;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
                input {
                  cursor: pointer;
                  position: absolute;
                  opacity: 0;
                  width: 50px;
                  height: 50px;
                  padding: 0px;
                }
              }
            }
          }
        }
        .select {
          margin-top: 10px;
          width: 100%;
          @media only screen and (min-width: $sm-screen) {
            padding-right: 20px;
          }
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          .selected-option {
            width: auto;
            font-weight: 500;
            font-size: 14px;
            background-color: rgba($primary-dark , 0.3);
            padding: 5px 12px ;
            border-radius: 5px;
            margin: 0px  10px 10px 0px;
          }
        }
        .files {
          width: 100%;
          margin-bottom: 20px;
          .list {
            margin-top: 5px;
            .file {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 10px;
              a {
                display: flex;
                align-items: center;
                text-decoration: none;
                padding: 6px 10px;
                background-color: rgba($primary-color ,0.1);
                width: 100%;
                border-radius: $radius;
                @include bold;
                color: $primary-color;
                @include transition;
                &.edit {
                  width: calc(100% - 30px);
                }
                &:hover {
                  background-color: rgba($primary-color ,0.2);
                }
              }
              button {
                @include btn;
                color: $primary-color;
                &:hover {
                  color: $danger;
                }
              }
            }
          }
          .input {
            margin-bottom: 20px;
            label {
              margin-top: 10px;
              @include label;
              color: $white;
            }
          }
        }
      }
    }
  }
}