@import '../../../styles/variables.scss';

.container {
  @include page-container;
  display: flex;
  flex-direction: column;
  .content {
    width: 100%;
    @include transition;
    &.filter-is-active {
      @media only screen and (min-width: $md-screen) {
        width: calc(100% - 360px);
      }
      .filter {
        @media only screen and (min-width: $md-screen) {
          display: none;
        }
      }
      header {
        @media only screen and (min-width: $md-screen) {
          padding: 20px 0px 0px 40px;
        }
      }
      .list {
        @media only screen and (min-width: $md-screen) {
          padding: 20px 10px 0px 40px;
        }
      }
      .col {
        width: 60%;
        @media only screen and (min-width: 1500px) {
          width: calc((100% - 40px) / 3 );
        }
        &.desktop {
          display: none;
          @media only screen and (min-width: 1500px) {
            display: flex;
          }
        }
      }
    } 
  }
  header {
    padding: 0px 20px;
    @media only screen and (min-width: $md-screen) {
      display: flex;
      justify-content: space-between;
      padding: 20px 40px;
    }
    h2 {
      @include title-2;
      text-transform: uppercase;
    }
    .btn-container {
      width: 100%;
      @media only screen and (min-width: $md-screen) {
        width: auto;
        display: flex;
        justify-content: flex-end;
      }
      a {
        display: block;
        text-decoration: none;
      }
      button {
        width: 100%;
        @media only screen and (min-width: $md-screen) {
          width: 220px;
        }
      }
      .filter {
        position: absolute;
        bottom: 20px;
        right: 20px;
        width: 40px;
        min-width: 75px;
        padding: 0px;
        z-index: 10;
        svg {
          margin-left: 0px;
          margin-right: 10px;
        }
        @media only screen and (min-width: $md-screen) {
          position: relative;
          bottom: auto;
          right: auto;
          margin-left: 20px;
        }
      }
    }
  }
  .list {
    position: relative;
    padding: 20px;
    width: 100%;
    max-height: calc(100vh - 114px);
    overflow-y: auto;
    @include scrollbar;
    z-index: 3;
    @media only screen and (min-width: $md-screen) {
      padding: 5px 40px;
      max-height: calc(100vh - 120px);
    }
  }
  .sort {
    display: none;
    @media only screen and (min-width: $md-screen) {
      display: block;
      padding: 0px 40px;
    }
  }
  .row {
    width: 100%;
    display: flex;
    text-decoration: none;
    &:not(.labels) {
      @include box;
      min-height: 60px;
      .col {
        &:not(:first-child) {
          border-left: 1px solid #EDECF4;
        }
      }
    }
    .col {
      display: flex;
      align-items: center;
      width: 60%;
      padding: 0px 10px;
      margin: 8px 0px;
      color:rgba(77, 78, 82, 1);
      @media only screen and (min-width: $md-screen) {
        width: calc((100% - 40px) / 3 );
      }
      &.desktop {
        display: none;
        @media only screen and (min-width: $md-screen) {
          display: flex;
        }
      }
      p {
        font-weight: 500;
        margin:0px;
        padding: 0px 10px;
        word-break:keep-all;
        &.bold {
          @include bold;
        }
        &.status {
          width: auto;
          font-weight: 500;
          font-size: 14px;
          color: $white;
          background-color: rgba($primary-color , 0.4);
          padding: 10px 12px ;
          border-radius: 5px;
          width: 130px;
          text-align: center;
          margin: 0px  10px 0px 0px;
          &.published {
            background-color: $primary-color;
          }
        }
      }
    }
    .edit {
      display: none;
      @media only screen and (min-width: 1300px) {
        display: flex;
        width: 40px;
        img {
          width: 20px;
        }
      }
    }
  }
}