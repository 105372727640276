@import '../../../../../styles/variables.scss';

.container {
  padding: 30px 50px;
  @media only screen and (min-width: 1270px) {
    width: 60vw;
  }
  label {
    @include label;
    color: rgba($dark, 0.7);
  }
  .bold {
    @include bold;
  }
  h3 {
    @include bold;
    font-size: 20px;
    margin: 0px 0px 30px;
    color: $primary-color;
  }
  .row {
    display: flex;
    flex-direction: column;
    width: 100%;
    @media only screen and (min-width: 1270px) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .input-block {
        width: calc(50% - 10px);
      }
      &.col-3 {
        .input-block {
          width: calc(33% - 10px);
        }
      }
    }
  }
  .input-block {
    margin-bottom: 15px;
    .input {  
      margin-top: 5px;
    }
  }
  .file {
    margin: 10px 0px 30px;
  }
  .btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    .edit {
      margin: 0px;
      font-family: "Anybody-SemiBold";
      color: $white;
      background-color: $primary-color;
    }
    .delete {
      @include btn;
      @include bold;
      padding: 10px 20px;
      background-color: transparent;
      color: $danger;
      border-radius: $radius;
      &:hover {
        background-color: $danger;
        color: $white;
      }
    }
  }
}