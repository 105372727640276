@import '../../../../styles/variables.scss';

.container {
  width: 100vw;
  height: 100%;
  padding: 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  @include scrollbar;
  z-index: 3;
  @media only screen and (min-width: $sm-screen) {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }
  @media only screen and (min-width: $md-screen) {
    width: calc(100vw - 200px);
  }
  .box {
    @include box-static;
    padding: 20px;
  }
  h3 {
    @include title-section;
    margin-bottom: 20px;
  }
  label {
    @include label;
    color: rgba($dark, 0.7);
    &.bold {
      @include bold;
      margin: 20px 0px 0px 0px;
      display: block;
      color: rgba($dark, 0.7);
    }
  }
  .border {
    @include border;
    padding-top: 0px;
  }
  .form-group {
    @include form-group;
    margin-top: 10px;
    margin-bottom: 20px;
    .bold {
      @include bold;
      color: rgba($dark, 0.7);
    }
    @media only screen and (min-width: $sm-screen) {
      margin-bottom: 10px;
    }
  }
  .check {
    @include check;
  }
  main {
    width: 100%;
    margin-bottom: 0px;
    @media only screen and (min-width: $sm-screen) {
      width: 85%;
    }
    @media only screen and (min-width: $lg-screen) {
      width: 70%;
    }
    .list {
      @include list;
      margin: 10px 0px;
      a {
        color: $primary-color;
      }
      p {
        min-width: 30%;
      }
    }
    .row {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 0px;
      @media only screen and (min-width: $sm-screen) {
        flex-direction: row;
        justify-content: space-between;
        .col {
          width: calc(33.33% - 10px);
        }
        .url {
          width: calc(67.66% - 10px);
        }
      }
    }
    .cips {
      margin-top: 15px;
      .cip {
        padding: 15px;
        width: 100%;
        margin-bottom: 10px;
        position: relative;
        background-color: #FAFBFB;
        border: 1px solid rgba($primary-color, 0.3);
        cursor: pointer;
        &:hover {
          background-color: #eef1f1;
        }
        .row {
          &:first-child {
            margin-bottom: 10px;
          }
        }
      } 
    }
    .add-btn {
      @include btn-primary;
    }
  }
}