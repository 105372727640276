@import '../../../../styles/variables.scss';

.container {
  width: 100vw;
  height: 100%;
  padding: 20px;
  overflow-y: auto;
  @include scrollbar;
  z-index: 3;
  @media only screen and (min-width: $md-screen) {
    min-width: calc((100vw - 200px));
  }
  .row {
    display: flex;
    flex-direction: column;
    @media only screen and (min-width: $md-screen) {
      flex-direction: row;
      justify-content: center;
    }
    .col {
      width: 100%;
      @media only screen and (min-width: $md-screen) {
        width: 70%;
        padding: 0px 10px;
      }
      .box {
        @include box-static;
        padding: 20px;
      }
      section {
        &:not(:last-child) {
          border-bottom: 1px solid #EDECF4;
          padding-bottom: 20px;
          margin-bottom: 20px;
          padding-top: 0px;
        }
        h3 {
          @include title-section;
          margin-bottom: 20px;
        }
        label {
          @include label;
          color: rgba($dark, 0.7);
        }
        .block {
          margin-bottom: 0px;
          .value {  
            margin-top: 5px;
          }
        }
        .input-row {
          @media only screen and (min-width: $sm-screen) {
          display: flex;
          justify-content: space-between;
          width: 100%;
            .block {
              width: calc(50% - 10px);
            }
          }
        }
      }
      .form-list {
        width: 100%;
        .drag {
          &:hover {
            background-color: rgba($primary-color , 0.08);
          }
          &:focus {
            background-color: rgba($primary-color , 0.16);
          }
        }
        .row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          .block {
            width: calc(100% - 30px);
            margin-bottom: 20px;
          }
          .actions {
            button {
              @include btn;
              svg {
                font-size: 20px;
                color: $primary-color;
                &:hover {
                  color: $danger;
                }
              }
            }
          }
        }
      }
      .input-list {
        display: flex;
        flex-direction: column;
        label {
          @include label;
          color: rgba($dark, 0.7);
        }
        .block {
          margin-bottom: 0px;
          .value {  
            margin-top: 5px;
          }
        }
        .row {
          width: 100%;
          margin: 20px 0px 0px 20px;
          display: flex;
          justify-content: flex-start;
          .block {
            width: calc(100% - 60px);
          }
          .actions {
            button {
              margin-top: 20px;
              @include btn;
              svg {
                margin: 30px 10px 0px 10px;
                font-size: 20px;
                color: $primary-color;
                &:hover {
                  color: $danger;
                }
              }
            }
          }
        }
        .add {
          @include btn;
          @include bold;
          color: $primary-color;
          text-align: left;
          margin-left: 20px;
          margin-top: 20px;
        }
        .tags-list {
          button {
            @include btn;
            @include label;
            @include bold;
            font-size: 14px;
            background-color: rgba($primary-color , 0.16);
            padding: 5px 12px ;
            border-radius: 5px;
            color: rgba($dark , 0.6);
            margin: 0px  10px 10px 0px;
            &:hover {
              background-color: rgba($primary-color , 0.4);
            }
            &.selected {
              color: $white;
              background-color: $primary-color;
            }
          }
        }
      }
      .create-field {
        background-color: rgba($primary-color , 0.16);
        padding: 20px;
        border-radius: $radius;
        .btn-container {
          display: flex;
          justify-content: flex-end;
          .btn {
            @include btn;
            @include label;
            @include bold;
            font-size: 14px;
            background-color: rgba($primary-color , 0.16);
            padding: 10px 20px;
            border-radius: 5px;
            color: rgba($dark , 0.6);
            margin: 20px 0px 0px 10px;
            &.valid {
              color: $white;
              background-color: $primary-color;
            }
          }
        }
      }
    }
  }
}

.input {
  @include transition;
  display: block;
  padding: 8px;
  border: none;
  outline: none;
  background-color: transparent;
  border-radius: 0px;
  width: 100%;
  min-width: 250px;
  margin-top: 5px;
  box-sizing: border-box;
  @include text;
  &:focus{
    border: none;
  }
  &::placeholder {
    color : #a3a3a3;
  }
  border-radius: $radius;
  min-height: 40px;
  min-width: 100px;
  background: #FAFBFB;
  border: 1px solid rgba(79, 60, 200, 0.3);
  color: $black;
  @include label;
  &:focus{
    border: 1px solid rgba(79, 60, 200, 1);
  }
}