@import '../../../../styles/variables.scss';

.container {
  margin: 0px auto 20px auto;
  width: calc(100% - 40px);
  @include box-static;
  padding: 15px 20px;
  @media only screen and (min-width: $md-screen) {
    width: 80%;
  }
  h1 {
    margin: 0px;
  }
  h2 {
    @include title-section;
    margin: 0px;
  }
  .border {
    @include border;
  }
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .toggle {
      @include btn;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $primary-color;
      width: 28px;
      height: 28px;
      border-radius: 15px;
      &:hover {
        background-color: rgba($primary-color, 0.2);
      }
      &.open {
        transform: rotate(180deg);
      }
    }
  }
  .content {
    @include transition;
    overflow: hidden;
    label {
      @include label;
      color: rgba($dark, 0.7);
    }
    .bold {
      @include bold;
    }
    .row {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      @media only screen and (min-width: $md-screen) {
        flex-direction: row;
        margin-top: 0px;
      }
      .col {
        flex: 1;
        &.icon {
          display: flex;
          align-items: center;
          svg {
            color: $primary-color;
            margin-right: 10px;
            transform: translateY(-2px);
          }
        }
      }
    }
    .text {
      margin-top: 20px;
    }
    .list {
      margin-bottom: 10px;
      p {
        background-color: rgba($primary-color, 0.2);
        padding: 6px 10px;
        border-radius: $radius;
      }
      a {
        @include transition;
        display: block;
        background-color: rgba($primary-color, 0.2);
        padding: 6px 10px;
        border-radius: $radius;
        color: $dark;
        margin: 8px 0px;
        &:hover {
          background-color: rgba($primary-color, 0.3);
        }
      }
      .cost {
        display: flex;
        background-color: transparent;
        margin: 8px 0px;
        p {
          @include bold;
          background-color: transparent;
          width: 50%;
          padding: 6px 0px;
          margin: 0px 0px;
          @media only screen and (min-width: $md-screen) {
            width: 25%;
          }
        }
      }
    }
  }
}