@import '../../../../styles/variables.scss';

.container {
  margin: 0px auto 20px auto;
  width: calc(100% - 40px);
  @include box-static;
  padding: 15px 20px;
  @media only screen and (min-width: $md-screen) {
    width: 80%;
  }
  h1 {
    margin: 0px;
  }
  h2 {
    @include title-section;
    margin: 0px;
  }
  .border {
    @include border;
  }
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .toggle {
      @include toggle;
    }
  }
  .content {
    @include transition;
    overflow: hidden;
    label {
      @include label;
      color: rgba($dark, 0.7);
    }
    .tasks {
      padding: 15px;
    }
  }
}