@import '../../styles/variables.scss';

.container {
  margin: 20px 0px;
  background: #FFFFFF;
  border: 1px solid rgba(79, 60, 200, 0.3);
  box-shadow: 0px 10px 28px #EBECED;
  border-radius: $radius;
  opacity: 0.3;
  min-height: 40px;
  @include transition;
  &.is-active {
    opacity: 1;
    &.is-edit {
      min-height: 90px;
    }
  }
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    .name {
      display: flex;
      align-items: center;
      h4 {
        @include title-2;
        text-transform: uppercase;
        font-size: 16px;
        line-height: 16px;
        margin: 8px
      }
    }
    button {
      @include btn;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $primary-color;
    }
  }
  .select {
    padding: 0px 10px 10px 10px ;
  }
}