@import '../../styles/variables.scss';

.icon {
  border-radius: $radius;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4px;
  box-shadow: 0px 6px 20px -4px rgba(136,136,136,0.57);
  color: $white;
  font-weight: bold;
  &.dashboard {
    margin: 0px;
  }
  img {
    border-radius: $radius;
    width: 100%;
  }
}